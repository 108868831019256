import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";

const ProjectContext = React.createContext();

const GET_PROJECTS = gql`
  query MyQuery($userId: uuid!) {
    projects_projects(
      order_by: { created_at: desc }
      where: {
        user_projects: { userId: { _eq: $userId } }
        deleted_at: { _is_null: true }
      }
    ) {
      description
      id
      projectName
      startDate
      endDate
      site {
        id
        name
        location
      }
      images {
        description
        id
        imageName
        url
      }
      milestones_aggregate {
        aggregate {
          count
        }
      }
      user_projects {
        user {
          fullName
          id
          user_roles {
            role {
              name
              code
            }
          }
        }
      }
      clients: user_projects(
        where: { user: { user_roles: { role: { code: { _eq: "CLNT" } } } } }
      ) {
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
    }
  }
`;

const GET_PROJECTS_ALL = gql`
  query MyQuery($where: projects_projects_bool_exp) {
    projects_projects(order_by: { created_at: desc }, where: $where) {
      description
      id
      projectName
      # logo
      # location
      startDate
      endDate
      site {
        id
        name
        location
      }
      images {
        description
        id
        imageName
        url
      }
      milestones_aggregate {
        aggregate {
          count
        }
      }
      user_projects {
        user {
          fullName
          id
          user_roles {
            role {
              name
              code
            }
          }
        }
      }
      contractors: user_projects(
        where: { user: { user_roles: { role: { code: { _eq: "CNTR" } } } } }
      ) {
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
      clients: user_projects(
        where: { user: { user_roles: { role: { code: { _eq: "CLNT" } } } } }
      ) {
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
    }
  }
`;

const GET_SITES = gql`
  query MyQuery($company_id: uuid!) {
    sites(
      where: {
        deleted_at: { _is_null: true }
        company_id: { _eq: $company_id }
      }
      order_by: { created_at: desc }
    ) {
      id
      name
      description
      location
      image
      projects(where: { deleted_at: { _is_null: true } }) {
        id
        projectName
      }
      site_admins: user_sites(
        where: { user: { user_roles: { role: { code: { _eq: "SITE_ADM" } } } } }
      ) {
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
      store_keepers: user_sites(
        where: { user: { user_roles: { role: { code: { _eq: "ST_KP" } } } } }
      ) {
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
    }
  }
`;

const GET_SITES_ALL = gql`
  query MyQuery($where: sites_bool_exp) {
    sites(where: $where, order_by: { created_at: desc }) {
      id
      name
      description
      location
      image
      projects(where: { deleted_at: { _is_null: true } }) {
        id
        projectName
      }
      site_admins: user_sites(
        where: { user: { user_roles: { role: { code: { _eq: "SITE_ADM" } } } } }
      ) {
        id
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
      store_keepers: user_sites(
        where: { user: { user_roles: { role: { code: { _eq: "ST_KP" } } } } }
      ) {
        id
        user {
          phone
          email
          fullName
          id
          photoURL
        }
      }
    }
  }
`;

const GET_COMPANY = gql`
  query MyQuery($id: uuid!) {
    company(id: $id) {
      created_at
      id
      logo
      name
    }
  }
`;
export function useProject() {
  return useContext(ProjectContext);
}

export function ProjectContextProvider(props) {
  const [currentProject, setCurrentProject] = useState();
  const auth = useAuthUser();
  const user = auth();
  useEffect(() => {
    refetchProjects();
    refetchSites();
  }, [user]);
  const isCompanyAdmin = user?.roles?.map((role) => role.code).includes("ADM");
  const isSuperAdmin = user?.roles?.map((role) => role.code).includes("SU");
  const isSiteAdmin = user?.roles
    ?.map((role) => role.code)
    .includes("SITE_ADM");
  const isStoreKeeper = user?.roles?.map((role) => role.code).includes("ST_KP");
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useQuery(GET_COMPANY, {
    variables: {
      id: user.company_id,
    },
    // context: { headers: { "x-hasura-role": isSuperAdmin ? "SU" : "ADM" } },
  });
  let variables = {};
  if (!isCompanyAdmin && !isSuperAdmin && !isSiteAdmin && !isStoreKeeper) {
    // contractors
    variables = {
      userId: user?.id,
    };
  } else if (isCompanyAdmin) {
    variables = {
      where: {
        deleted_at: { _is_null: true },
        site: { company_id: { _eq: user?.company_id } },
      },
    };
  } else if (isSiteAdmin || isStoreKeeper) {
    variables = {
      where: {
        deleted_at: { _is_null: true },
        site: {
          user_sites: { user_id: { _eq: user?.id } },
        },
      },
    };
  } else if (isSuperAdmin) {
    variables = {
      where: {
        deleted_at: { _is_null: true },
      },
    };
  }
  console.log(variables);
  const { data, loading, error, refetch, networkStatus } = useQuery(
    isSuperAdmin || isCompanyAdmin || isSiteAdmin || isStoreKeeper
      ? GET_PROJECTS_ALL
      : GET_PROJECTS,
    {
      variables,
      skip: user ? false : true,
      // context: { headers: { "x-hasura-role": isSuperAdmin ? "SU" : "ADM" } },
    }
  );

  let variables2 = {};
  if (!isCompanyAdmin && !isSuperAdmin && !isSiteAdmin && !isStoreKeeper) {
    variables2 = {
      company_id: user?.company_id,
    };
  } else if (isSiteAdmin || isStoreKeeper) {
    variables2 = {
      where: {
        deleted_at: { _is_null: true },
        user_sites: { user_id: { _eq: user?.id } },
      },
    };
  } else if (isCompanyAdmin) {
    variables2 = {
      where: {
        deleted_at: { _is_null: true },
        company_id: { _eq: user?.company_id },
      },
    };
  } else if (isSuperAdmin) {
    variables2 = {
      where: {
        deleted_at: { _is_null: true },
      },
    };
  }
  const {
    data: site_data,
    loading: sitesLoading,
    error: sitesError,
    refetch: refetchSites,
    networkStatus: sitesNetworkStatus,
  } = useQuery(
    isSuperAdmin || isCompanyAdmin || isSiteAdmin || isStoreKeeper
      ? GET_SITES_ALL
      : GET_SITES,
    {
      variables: variables2,
      skip: user ? false : true,
      // context: { headers: { "x-hasura-role": isSuperAdmin ? "SU" : "ADM" } },
    }
  );

  const clear = () => {
    setCurrentProject(null);
    localStorage.clear();
  };
  const changeProject = (project) => {
    setCurrentProject(project);
    localStorage.setItem("currentProject", JSON.stringify(project));
  };
  useEffect(() => {
    if (
      !localStorage.getItem("currentProject") ||
      localStorage.getItem("currentProject") === "undefined"
    ) {
      setCurrentProject(data?.projects_projects[0]);
      localStorage.setItem(
        "currentProject",
        JSON.stringify(data?.projects_projects[0])
      );
    } else {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      data?.projects_projects.map((project) => {
        if (currentProject?.id === project.id) {
          localStorage.setItem("currentProject", JSON.stringify(project));
          setCurrentProject(project);
        }
      });
      if (!loading && data?.projects_projects?.length === 0) {
        clear();
      }
    }
  }, [data?.projects_projects, loading]);
  const refetchProjects = async () => {
    const response = await refetch();
    response?.data?.projects_projects.map((project) => {
      if (currentProject?.id === project.id) {
        localStorage.setItem("currentProject", JSON.stringify(project));
        setCurrentProject(project);
      }
    });
  };
  console.log(data);
  const contextValue = {
    company: companyData?.company,
    companyLoading,
    companyError,
    currentProject,
    setCurrentProject,
    changeProject,
    clear,
    projects: data?.projects_projects,
    loading,
    error,
    refetchProjects,
    networkStatus,
    sites: site_data?.sites,
    sitesLoading,
    sitesError,
    refetchSites,
    sitesNetworkStatus,
  };
  return (
    <ProjectContext.Provider value={contextValue}>
      {props.children}
    </ProjectContext.Provider>
  );
}

export default ProjectContext;
