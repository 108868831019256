import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import login1 from "../assets/illustrations/login1.svg";
import login2 from "../assets/illustrations/login2.svg";
import login3 from "../assets/illustrations/login3.svg";
import bg from "../assets/illustrations/gradient-bg.svg";
// import logo from "../assets/images/logo.png";
import Checkbox from "../components/UI/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlineIcon from "@mui/icons-material/LockOutlined";
import useInput from "../hooks/use-input";
import Slideshow from "../components/UI/Slideshow";
import { Fragment, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Toast from "../components/UI/Toast";
import { useSignIn } from "react-auth-kit";
import logo from "../assets/images/logo.png";

const LOGIN = gql`
  mutation MyMutation2($object: loginInput!) {
    login(object: $object) {
      metadata
      token
    }
  }
`;
const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [
    loginUser,
    { loading: isLoggingIn, error: loginError }, // change loginError2 to loginError
  ] = useMutation(LOGIN);
  const signIn = useSignIn();
  const sections = [
    {
      image: login1,
      title: (
        <Fragment>
          Stay on top of your construction project with
          <br />
          <span className="text-primary">CONIFY</span>
        </Fragment>
      ),
    },
    {
      image: login2,
      title: "Keep your clients up to date with latest progress of your work",
    },
    {
      image: login3,
      title: "Optimize your work performance like never before",
    },
  ];
  const {
    value: password,
    isValid: passwordIsValid,
    isInvalid: passwordIsInValid,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: email,
    isValid: emailIsValid,
    isInvalid: emailIsInValid,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(
    (value) => value.trim() !== "" && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  );
  const formIsValid = emailIsValid && passwordIsValid;
  const loginHandler = async (e) => {
    e.preventDefault();
    if (formIsValid) {
      const userData = {
        email: email,
        password: password,
      };
      try {
        const response = await loginUser({ variables: { object: userData } });
        if (response?.data?.login) {
          localStorage.setItem("token", response?.data?.login.token);
          signIn({
            token: response?.data?.login.token,
            expiresIn: 3600,
            tokenType: "Bearer",
            authState: JSON.parse(response.data.login.metadata),
          });
          navigate("/");
        } else {
          setError("Login not successfull");
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
        return false;
      }
    }
  };
  return (
    <div
      className="grid grid-cols-12 h-screen items-center"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <form
        onSubmit={loginHandler}
        className="flex max-w-md flex-col gap-4 col-start-2 col-span-4"
      >
        <div>
          <div className="mb-8 flex items-center justify-center">
            <img className="w-48" src={logo} alt="Conify logo" />
          </div>
          <h1 className="text-xl mb-4 font-bold">Login</h1>
          <Input
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={email}
            error={emailIsInValid}
            icon={<MailOutlineIcon className="!text-lg" />}
            // success={success}
            className="mb-3"
            id="email"
            label="Email"
            helperText={<>A valid email is required</>}
            placeholder="youremail@gmail.com"
            required
            type="email"
          />
        </div>
        <Input
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          value={password}
          error={passwordIsInValid}
          icon={<LockOutlineIcon className="!text-lg" />}
          label="Password"
          // success={success}
          // error={error}
          className="mb-3"
          color="failure"
          placeholder="Password"
          helperText={<>Password is required</>}
          id="password"
          required
          type="password"
        />
        {/* <div className="flex justify-between">
          <Checkbox onClick={() => {}} id="remember-me" label="Remember me" />

          <Link
            to="#"
            className="text-sm font-medium text-primary dark:text-blue-500 hover:underline"
          >
            Forgot password?
          </Link>
        </div> */}
        <Button
          disabled={!formIsValid}
          type="submit"
          isLoading={isLoggingIn}
          onClick={loginHandler}
          className="py-3 mt-5"
        >
          Log In
        </Button>
      </form>
      {/* Illustration */}

      <Slideshow className="col-start-7 col-span-5" sections={sections} />
    </div>
  );
};

export default LoginPage;
