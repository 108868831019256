import { useContext, useState, useCallback, Fragment, useEffect } from "react";
import UserItem from "./UserItem";
import { ChatContext } from "../../store/chat-context";
import { useUser } from "../../store/user-context";
import { useMediaQuery } from "react-responsive";
import { Dialog, Transition } from "@headlessui/react";
import Toast from "../UI/Toast";
import "../../index.css";
import { useAuthUser } from "react-auth-kit";
import { gql, useSubscription } from "@apollo/client";
import SearchFromList from "../UI/SearchFromList";
import { useLocation, useParams } from "react-router-dom";

const GET_USER_CHATS = gql`
  subscription MyQuery($user_id: uuid!) {
    users_user_chats(
      where: {
        _or: [
          { reciever_id: { _eq: $user_id } }
          { sender_id: { _eq: $user_id } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      last_message
      seen
      id
      created_at
      combined_id
      reciever_id
      sender_id
      user {
        id
        fullName
        photoURL
        user_roles {
          role {
            code
            id
            name
          }
        }
      }
      userBySenderId {
        id
        fullName
        photoURL
        user_roles {
          role {
            code
            id
            name
          }
        }
      }
    }
  }
`;
const UsersList = (props) => {
  const [error, setError] = useState(null);
  const { dispatch } = useContext(ChatContext);
  const auth = useAuthUser();
  const user = auth();
  const { users, loading: usersLoading, error: usersError } = useUser();
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });
  const { sidebarShown, setSidebarShown, setUserSelected } = props;
  const query = useLocation();
  console.log(query);
  const {
    data: userChats,
    loading: userChatsLoading,
    error: userChatsError,
  } = useSubscription(GET_USER_CHATS, {
    variables: {
      user_id: user.id,
    },
  });
  const handleSelect = useCallback(
    async (u) => {
      if (!u.roles) {
        const roles = u.user_roles.map((user_role) => {
          return user_role.role;
        });
        u.roles = roles;
      }

      dispatch({ type: "CHANGE_USER", payload: u });
      setUserSelected(true);
      setSidebarShown(false);
    },
    [dispatch, setUserSelected, setSidebarShown, user.id]
  );
  useEffect(() => {
    console.log(props.userId);
    if(props.userId && users){
      users.map(user => {
        if(user.id === props.userId){
          handleSelect(user);
        }
      })
    }
  }, [props.userId, users])
  const sidebar = (
    <div
      className={`chat-scrollbar overflow-y-auto ${props.className}`}
    >
      <div className="pt-3 h-full w-full bg-clip-padding bg-opacity-25 text-gray-600 bg-gray-50">
        <div className="px-2">
          <SearchFromList
            className="w-full bg-white/40 focus:bg-white/70 focus:!ring-gray-200 rounded-md text-sm"
            onClick={(user) => handleSelect(user)}
            list={users}
            propertiesKey={"fullName"}
            placeholder={"Find a user"}
          />
        </div>
        <div className="my-3">
          <p>{userChatsError}</p>
          {/* {!isLoggedIn && <p>Please <Link to="/login">login</Link> to your account</p>} */}
          {usersLoading || userChatsLoading ? (
            <p className="mt-10 font-semibold text-gray-600 text-center text-sm">
              Loading...
            </p>
          ) : usersError || userChatsError ? (
            <p className="mt-10 font-semibold text-gray-600 text-center text-sm">
              {usersError?.message || userChatsError?.message}
            </p>
          ) : userChats && userChats.users_user_chats.length ? (
            userChats.users_user_chats.map((chat, index) => (
              <>
              <UserItem
                sidebarShown={sidebarShown}
                setSidebarShown={setSidebarShown}
                key={index}
                id={chat.id}
                fullName={
                  chat.reciever_id === user.id
                    ? chat.userBySenderId.fullName
                    : chat.user.fullName
                }
                sender={chat.sender_id}
                isSender={chat.sender_id === user.id}
                photoURL={
                  chat.reciever_id === user.id
                    ? chat.userBySenderId.photoURL
                    : chat.user.photoURL
                }
                seen={chat.seen}
                last_message={chat.last_message}
                date={chat.created_at}
                onClick={() => {
                  chat.reciever_id === user.id
                    ? handleSelect(chat.userBySenderId)
                    : handleSelect(chat.user);
                }}
              />
              </>
            ))
          ) : (
            users.length && (
              <div>
                <h1 className="font-semibold text-gray-600 pl-2 my-4">
                  Suggested users
                </h1>
                {users &&
                  users.map(
                    (user, index) =>
                      user.fullName && (
                        <UserItem
                          sidebarShown={sidebarShown}
                          setSidebarShown={setSidebarShown}
                          key={index}
                          id={user.id}
                          // uid={user.uid || null}
                          fullName={user.fullName}
                          photoURL={user.photoURL}
                          last_message={user.roles
                            .map((role) => role.name)
                            .join(", ")}
                          date={null}
                          onClick={() => handleSelect(user)}
                        />
                      )
                  )}
              </div>
            )
          )}
        </div>

        {/* {} */}
      </div>
      {error && (
        <Toast type="error" show={true} setState={setError} message={error} />
      )}
    </div>
  );
  return (
    <Fragment>
      <Transition appear show={sidebarShown} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-10`}
          onClose={() => setSidebarShown(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="-left-full"
            enterTo="-left-0"
            leave="ease-in duration-100"
            leaveFrom="-left-0"
            leaveTo="-left-full"
          >
            <Dialog.Panel
              className={`fixed sm:absolute inset-0 w-full max-w-md transform overflow-hidden transition-all`}
            >
              {isMobile && sidebar}
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
      {!isMobile && sidebar}
    </Fragment>
  );
};

export default UsersList;
