import React, { useEffect, useState } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import { useProject } from "../../store/project-context";
import Compressor from "compressorjs";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Progress from "../UI/Progress";
import DragAndDrop from "../UI/DragAndDrop";
import Image from "../UI/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "../UI/IconButton";
import { uploadImage } from "../../functions/api";
import { useUser } from "../../store/user-context";

const CREATE_COMPANY = gql`
  mutation MyMutation($object: CreateCompanyInput!) {
    createCompany(object: $object) {
      id
    }
  }
`;
const UPDATE_COMPANY = gql`
  mutation MyMutation($id: uuid = "", $_set: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const NewUser = (props) => {
  //   const [selectedProjects, setSelectedProjects] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [image, setImage] = useState(null);
  const { refetch: refetchUsers } = useUser();
  const { refetchProjects } = useProject();
  const [
    updateCompany,
    { loading: isCompanyUpdating, error: updateUserError },
  ] = useMutation(UPDATE_COMPANY);
  const [
    createCompany,
    { loading: isCompanySubmitting, error: companyUserError },
  ] = useMutation(CREATE_COMPANY);

  const { selectedCompany, editMode, setSuccess, setError } = props;
  //   const projects = useProject();
  //   const { data: roles } = useQuery(GET_ROLES);
  const {
    value: name,
    isValid: nameIsValid,
    isInvalid: nameIsInValid,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: adminFullName,
    isValid: adminFullNameIsValid,
    isInvalid: adminFullNameIsInValid,
    inputChangeHandler: adminFullNameChangeHandler,
    inputBlurHandler: adminFullNameBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phone,
    isValid: phoneIsValid,
    isInvalid: phoneIsInValid,
    inputChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => value.trim() !== "" && isPossiblePhoneNumber(value));

  const {
    value: email,
    isValid: emailIsValid,
    isInvalid: emailIsInValid,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(
    (value) => value.trim() !== "" && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  );

  const formIsValid =
    nameIsValid && adminFullNameIsValid && phoneIsValid && emailIsValid;
  const submitHandler = async (e) => {
    let companyData = {};
    if (editMode) {
      companyData = {
        name,
      };
    } else {
      companyData = {
        name,
        adminFullName,
        phone,
        email,
      };
    }
    if (uploadedImage) {
      companyData.logo = await uploadImage({
        image: uploadedImage,
        setUploadProgress,
        setError,
      });
    } else if (selectedCompany && selectedCompany.logo) {
      if (image) {
        companyData.logo = selectedCompany.logo;
      } else {
        companyData.logo = null;
      }
    }
    console.log(companyData);
    try {
      if (selectedCompany && editMode) {
        await updateCompany({
          variables: {
            id: selectedCompany.id,
            _set: companyData,
          },
        });
        if (!updateUserError) {
          setSuccess("Company updated successfully");
        }
      } else {
        const response = await createCompany({
          variables: { object: companyData },
        });
        if (response?.data?.createCompany) {
          if (response.data.createCompany.id && !companyUserError) {
            setSuccess("Company added successfully");
          }
        } else {
          setError("An error occurred while registering company");
        }
      }
      props.hideModal();
      props.refetch();
      refetchUsers();
      refetchProjects();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  const deleteImage = () => {
    setUploadedImage(null);
    setImage(null);
  };
  function handleImageUpload(files) {
    Object.values(files).map(async (file) => {
      const formData = new FormData();

      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      setImage(URL.createObjectURL(compressedBlob));
      formData.append("image", compressedBlob, compressedBlob.name);
      setUploadedImage(formData);
    });
  }
  // const addRole = (item, index) => {
  //   console.log(item);
  //   setSelectedRoles((selectedRoles) => [item, ...selectedRoles]);
  // };
  // const removeRole = (i) => {
  //   setSelectedRoles(selectedRoles.filter((role, index) => i !== index));
  // };
  useEffect(() => {
    if (selectedCompany && editMode) {
      adminFullNameChangeHandler(selectedCompany.admin[0]?.fullName);
      nameChangeHandler(selectedCompany?.name);
      phoneChangeHandler(selectedCompany.admin[0]?.phone);
      emailChangeHandler(selectedCompany.admin[0]?.email);
      setImage(selectedCompany?.logo);
    }
  }, [
    editMode,
    selectedCompany,
    adminFullNameChangeHandler,
    phoneChangeHandler,
    emailChangeHandler,
    setImage,
  ]);

  if (props.isShown) {
    return (
      <Modal
        headerIsShown={true}
        modalHeight={`${
          editMode ? "h-[60%] 2xl:h-[50%]" : "h-[85%] sm:h-[97%] 2xl:h-[85%]"
        }`}
        isShown={true} //props.isShown
        hideModal={props.hideModal}
        modalTitle={editMode ? "Edit Company" : "Add New Company"}
        modalBottom={
          <div className="flex justify-end mr-4">
            <Button
              className="mr-3"
              type="light"
              onClick={props.hideModal}
              isLoading={isCompanySubmitting}
            >
              Cancel
            </Button>
            <Button
              disabled={!formIsValid}
              isLoading={
                isCompanySubmitting || isCompanyUpdating || uploadProgress !== 0
              }
              onClick={submitHandler}
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </Button>
          </div>
        }
      >
        <p className="text-dark-gray text-sm ">
          {!editMode && "Register a new company"}
        </p>
        <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
          {/* <h3 className="font-semibold text-sm mb-2">Company Info</h3> */}
          <Input
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            error={nameIsInValid}
            id="CompanyFullName"
            label="Company name"
            helperText={<>Company name is required</>}
            placeholder="Your company name"
            required
            name="name"
            type="text"
          />
          <label
            htmlFor="input-group-1"
            className={`flex flex-col justify-between text-start w-full text-sm font-medium text-gray-600 dark:text-white`}
          >
            Logo(optional)
            {uploadProgress !== 0 && (
              <Progress className="w-full mt-2" progress={uploadProgress} />
            )}
          </label>
          {!image ? (
            <div className="flex flex-col pb-2 justify-center w-full mb-2">
              <DragAndDrop
                onDrop={handleImageUpload}
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    JPG, PNG or TIFF (MAX. 10MB)
                  </p>
                </div>
                <input
                  accept="image/jpeg, image/jpg, image/png, image/tiff"
                  onChange={(event) => {
                    handleImageUpload(event.target.files);
                  }}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </DragAndDrop>
            </div>
          ) : (
            <div className="flex justify-center items-center space-x-2 mb-2 h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
              <div className="group relative">
                <Image
                  width="w-24"
                  height="h-24"
                  skeletonWidth="w-10"
                  skeletonHeight="h-10"
                  src={image}
                  alt="Project"
                  className="object-cover rounded-md"
                />
                <IconButton
                  className="hidden group-hover:flex absolute top-0 bottom-0 left-0 right-0 m-auto border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                  type="rounded"
                  onClick={deleteImage}
                >
                  <DeleteIcon className="!w-4 !h-4" />
                </IconButton>
              </div>
            </div>
          )}
          {!editMode && (
            <>
              <h3 className="font-semibold text-sm mb-2">Company Admin Info</h3>
              <Input
                onChange={adminFullNameChangeHandler}
                onBlur={adminFullNameBlurHandler}
                value={adminFullName}
                error={adminFullNameIsInValid}
                id="adminFullName"
                label="Full name"
                helperText={<>Full name is required</>}
                placeholder="Adam sandler"
                required
                name="name"
                type="text"
              />
              <Input
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                value={email}
                error={emailIsInValid}
                id="email"
                label="Email"
                helperText={<>A valid email is required</>}
                placeholder="adminemail@gmail.com"
                required
                type="email"
              />
              <>
                <label
                  htmlFor="input-group-1"
                  className={`block mb-2 w-fit text-sm font-medium text-gray-600 dark:text-white ${
                    phoneIsInValid &&
                    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500"
                  }`}
                >
                  Phone
                </label>
                <PhoneInput
                  countryCallingCodeEditable={false}
                  international
                  onChange={phoneChangeHandler}
                  onBlur={phoneBlurHandler}
                  defaultCountry="ET"
                  value={phone}
                  id="phone"
                  label="Phone"
                  placeholder="9 00000000"
                  error={phoneIsInValid}
                  required
                  inputComponent={Input}
                />
              </>
            </>
          )}
        </form>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default NewUser;
