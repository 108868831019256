import { useContext } from "react";
import { ChatContext } from "../../store/chat-context";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";

const UserItem = (props) => {
  let hours = new Date(props.date).getHours();
  let minutes = new Date(props.date).getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  const AMPM = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const date = hours + ":" + minutes + " " + AMPM;
  const { data } = useContext(ChatContext);
  console.log(props.isSender);
  return (
    <button
      onClick={props.onClick}
      style={{ backdropFilter: `blur(0px)` }}
      className={`hover:bg-white/40 focus:bg-opacity-100 bg-opacity-0 bg-[whitesmoke] active:bg-opacity-70 researcher group w-full p-2 border-b border-white/40 flex ${
        props.id === data.user.id && "!bg-white/60"
      }`}
    >
      <img
        className="object-cover w-10 h-10 rounded-full"
        src={
          props.photoURL ||
          "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
        }
        alt="User profile"
      />
      <div className="ml-2 w-[90%] flex flex-col items-start text-sm text-slate-600">
        <p className="flex justify-between w-full font-semibold group-hover:text-slate-800">
          <span>{props.fullName}</span>
          <span className="text-[12px]">
            {props.isSender &&
              (props.seen ? (
                <DoneAllIcon className="!text-sm text-secondary" />
              ) : (
                <CheckIcon className="!text-sm text-secondary" />
              ))}
            {props.date && (
              <span className="font-medium ml-1.5">{date}</span>
            )}
          </span>
        </p>
        <p className="overflow-hidden w-full whitespace-nowrap text-ellipsis text-left">{props.last_message}</p>
      </div>
    </button>
  );
};
export default UserItem;
