import React, { useState, useRef } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Button from "./Button";

const Input = React.forwardRef((props, ref) => {
  // const inputRef = useRef();

  const successInputClasses =
    "bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-lg focus:ring-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400";
  const errorInputClasses =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-300 block w-full p-2.5 dark:bg-red-100 dark:border-red-400";
  const successLabelClasses =
    "block mb-2 text-sm font-medium text-green-600 dark:text-green-500";
  const errorLabelClasses =
    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500";
  const [passwordVisible, setPasswordVisible] = useState(false);
  if (props.type === "password") {
    return (
      <div className={`w-full ${props.className}`}>
        <label
          htmlFor="input-group-1"
          className={`block mb-2 w-fit text-sm font-medium text-gray-600 dark:text-white ${
            props.success && successLabelClasses
          } ${props.error && errorLabelClasses}`}
        >
          {props.label}
        </label>
        <div className="flex items-center">
          <div className="relative w-full">
            <div className="text-dark-gray absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {props.icon}
            </div>
            <input
              disabled={props.disabled}
              onChange={props.onChange}
              onKeyDown={props.onKeyDown}
              onBlur={props.onBlur}
              value={props.value}
              type={passwordVisible ? "text" : "password"}
              // id="input-group-1"
              className={`${
                props.bg ? props.bg : "bg-gray-50"
              } disabled:text-gray-400 disabled:cursor-not-allowed border-none placeholder:text-gray-400 focus:ring-4 focus:ring-primary/30 text-gray-900 hover:bg-dark-gray/20 text-sm rounded-lg block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-800 dark:text-white dark:focus:ring-primary dark:focus:border-primary ${
                props.success && successInputClasses
              } ${props.error && errorInputClasses}`}
              placeholder={props.placeholder || undefined}
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="absolute right-3 top-1/2 -translate-y-1/2"
            >
              {passwordVisible ? (
                <EyeSlash className="text-lg text-gray-500" />
              ) : (
                <Eye className="text-lg text-gray-500" />
              )}
            </button>
          </div>
          {props.generatePassword && (
            <Button type="x-small" className="ml-2 py-1 px-1">
              Generate
            </Button>
          )}
        </div>
        {props.success && (
          <p className="mt-2 text-sm text-green-600 dark:text-green-500">
            {props.success}
          </p>
        )}
        {props.error && props.helperText && (
          <p className="mt-2 text-xs tracking-wide text-red-600 dark:text-red-500">
            {props.helperText}
          </p>
        )}
      </div>
    );
  } else if (props.type === "tel") {
    return (
      <div className={`relative ml-2 ${props.className}`}>
        <div className="relative">
          {props.icon && (
            <div className="text-dark-gray absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {props.icon}
            </div>
          )}
          <input
            disabled={props.disabled}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            onBlur={props.onBlur}
            value={props.value}
            ref={props.innerRef || ref}
            type={props.type || "text"}
            min={props.min}
            max={props.max}
            // id="input-group-1"
            className={`${
              props.bg ? props.bg : "bg-gray-50"
            } border-none text-gray-900 hover:bg-dark-gray/20 text-sm rounded-lg focus:ring-4 focus:ring-primary/30 block w-full ${
              props.icon && "pl-10"
            } p-2.5 disabled:text-gray-400 disabled:cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 placeholder:text-gray-400 dark:text-white dark:focus:ring-primary ${
              props.success && successInputClasses
            } ${props.error && errorInputClasses}`}
            placeholder={props.placeholder || undefined}
          />
        </div>
        {props.success && (
          <p className="mt-2 text-xs text-green-600 dark:text-green-500">
            {props.success}
          </p>
        )}
        {props.error && props.helperText && (
          <p className="mt-2 text-xs tracking-wide text-red-600 dark:text-red-500">
            {props.helperText}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div className={`relative ${props.className}`}>
        <label
          htmlFor="input-group-1"
          className={`block mb-2 w-fit text-sm font-medium text-gray-600 dark:text-white ${
            props.success && successLabelClasses
          } ${props.error && errorLabelClasses}`}
        >
          {props.label}
        </label>
        <div className="relative">
          {props.icon && (
            <div className="text-dark-gray absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {props.icon}
            </div>
          )}
          <input
            disabled={props.disabled}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            onBlur={props.onBlur}
            value={props.value}
            ref={props.innerRef || ref}
            type={props.type || "text"}
            min={props.min}
            max={props.max}
            // id="input-group-1"
            className={`${
              props.bg ? props.bg : "bg-gray-50"
            } border-none text-gray-900 hover:bg-dark-gray/20 text-sm rounded-lg focus:ring-4 focus:ring-primary/30 block w-full ${
              props.icon && "pl-10"
            } p-2.5 disabled:text-gray-400 disabled:cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 placeholder:text-gray-400 dark:text-white dark:focus:ring-primary ${
              props.success && successInputClasses
            } ${props.error && errorInputClasses}`}
            placeholder={props.placeholder || undefined}
          />
          <span className="absolute right-8 top-1/2 -translate-y-1/2">
            {props.suffix}
          </span>
        </div>
        {props.success && (
          <p className="mt-2 text-xs text-green-600 dark:text-green-500">
            {props.success}
          </p>
        )}
        {props.error && props.helperText && (
          <p className="mt-2 text-xs tracking-wide text-red-600 dark:text-red-500">
            {props.helperText}
          </p>
        )}
      </div>
    );
  }
});

export default Input;
