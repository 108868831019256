import React, { useContext, useState } from "react";

const PhaseContext = React.createContext();

export function usePhase() {
  return useContext(PhaseContext);
}

export function PhaseContextProvider(props) {
  const [phaseData, setPhaseData] = useState(null);
  const storePhaseData = (newData) => {
    console.log("STORING...");
    console.log(newData);
    setPhaseData({
      ...phaseData,
      ...newData,
    });
  };
  const contextValue = {
    phaseData,
    setPhaseData,
    storePhaseData,
  };
  return (
    <PhaseContext.Provider value={contextValue}>
      {props.children}
    </PhaseContext.Provider>
  );
}

export default PhaseContext;
