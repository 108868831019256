import Button from "../UI/Button";
import Checkbox from "../UI/Checkbox";
import Input from "../UI/Input";
import Card from "../UI/Card";
import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import Toast from "../UI/Toast";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../store/project-context";
import VerifiedIcon from "@mui/icons-material/Verified";
import { CheckCircle } from "react-bootstrap-icons";

const INSERT_REPORT = gql`
  mutation MyMutation($objects: [projects_report_insert_input!]!) {
    insert_projects_report(objects: $objects) {
      affected_rows
    }
  }
`;
const SET_CHECKED = gql`
  mutation MyMutation($_in: [uuid!]!) {
    update_projects_checklist(
      where: { id: { _in: $_in } }
      _set: { isChecked: true }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_QUANTITY = gql`
  mutation MyMutation($id: uuid!, $quantity: Int!) {
    update_projects_storage_by_pk(
      pk_columns: { id: $id }
      _set: { quantity: $quantity }
    ) {
      quantity
    }
  }
`;
const Active = (props) => {
  const [
    insertReport,
    { loading: isReportSubmitting, error: insertReportError },
  ] = useMutation(INSERT_REPORT);
  const [
    setChecked,
    // { loading: isReportSubmitting, error: insertReportError },
  ] = useMutation(SET_CHECKED);
  const [
    updateQuantity,
    // { loading: isReportSubmitting, error: insertReportError },
  ] = useMutation(UPDATE_QUANTITY);
  const {
    selectedMilestone,
    setSelectedMilestone,
    notes,
    setData,
    data,
    date,
  } = props;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  // const [reportData, setReportData] = useState(data);
  const viewRef = useRef();
  const { currentProject } = useProject();

  let activeMilestone = { ...selectedMilestone };
  console.log(activeMilestone);
  // let activeMilestone = JSON.parse(JSON.stringify(selectedMilestone));
  const fillData = () => {
    setData(
      data.map((el) => {
        if (el.id === selectedMilestone.id) {
          return activeMilestone;
        } else {
          return el;
        }
      })
    );
    setSelectedMilestone(activeMilestone);
  };
  console.log(selectedMilestone);
  useEffect(() => {
    if (selectedMilestone)
      viewRef.current && viewRef.current.scrollIntoView({ behavior: "smooth" });
  }, [selectedMilestone]);

  const changeChecklistIsChecked = (id, checked) => {
    console.log(activeMilestone);
    activeMilestone = {
      ...activeMilestone,
      checklists: activeMilestone?.checklists?.map((item) => {
        if (id === item.id) {
          return { ...item, isChecked: checked, tempChecked: checked };
        } else return item;
      }),
    };
    fillData();
  };
  const taskChangeHandler = (index, quantity) => {
    activeMilestone = {
      ...activeMilestone,
      quantifiable_tasks: activeMilestone?.quantifiable_tasks?.map(
        (item, i) => {
          if (index === i) {
            return {
              ...item,
              quantity: quantity !== "" ? parseInt(quantity) : 0,
            };
          } else return item;
        }
      ),
    };
    fillData();
  };
  const materialsChangeHandler = (index, quantity) => {
    activeMilestone = {
      ...activeMilestone,
      materials: activeMilestone?.materials?.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            quantity: quantity !== "" ? parseInt(quantity) : 0,
          };
        } else return item;
      }),
    };
    fillData();
  };
  const equipmentsChangeHandler = (index, quantity) => {
    activeMilestone = {
      ...activeMilestone,
      equipments: activeMilestone?.equipments?.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            quantity: quantity !== "" ? parseInt(quantity) : 0,
          };
        } else return item;
      }),
    };
    fillData();
  };
  const laborChangeHandler = (index, quantity) => {
    activeMilestone = {
      ...activeMilestone,
      labors: activeMilestone?.labors.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            quantity: quantity !== "" ? parseInt(quantity) : 0,
          };
        } else return item;
      }),
    };
    fillData();
  };
  const submitHandler = async () => {
    console.log(data);
    // return;
    let total_required_materials = {};
    try {
      if (currentProject.id && selectedMilestone.id) {
        let checkedChecklists = [];
        let storages_to_update = [];
        let error = false;
        const objects = data?.map((item) => {
          const equipment = item.equipments.map((eq) => {
            if (eq.quantity > eq.requiredQuantity) {
              setError(
                `Only ${eq.requiredQuantity} ${eq.equipment.unit} ${eq.equipment.name} available`
              );
              error = true;
              return;
            }
            return {
              ...eq.equipment,
              quantity: eq.quantity,
              unitPrice: eq.unitPrice,
            };
          });
          item.materials.map((mat) => {
            if (total_required_materials[mat.material.id]) {
              total_required_materials[mat.material.id] =
                total_required_materials[mat.material.id] + mat.quantity;
            } else {
              total_required_materials[mat.material.id] = mat.quantity;
            }
          });
          console.log(total_required_materials);
          const materials = item.materials.map((mat) => {
            let quantity = mat.quantity;
            let totalPrice = 0;
            let log = [];
            console.log(mat.material);
            // assign unitPrice of the material as default
            let unitPrice = mat.unitPrice;
            // loop through the storage of the material
            mat.material.storages.map((storage) => {
              if (quantity === 0) {
                return;
              }
              console.log(storage);
              // skip if storage has no items
              if (storage.quantity !== 0) {
                // if the quantity(remaining) inside storage is >= the required, assign the unitPrice inside the storage (the actual cost of the material)
                // mat.quantity = the required amount
                if (storage.quantity >= quantity) {
                  unitPrice = storage.unitPrice;
                  totalPrice += storage.unitPrice * quantity;
                  console.log(storage.id);
                  console.log(storage.quantity, quantity);
                  // storage.quantity = storage.quantity - quantity; // update the storage quantity
                  console.log(total_required_materials[mat.material.id]);
                  // we subtract the material used in each milestone
                  log.push({
                    storageId: storage.id,
                    unitPrice: storage.unitPrice,
                    quantity,
                    remaining:
                      storage.quantity -
                      total_required_materials[mat.material.id],
                  });
                  storages_to_update.push({
                    id: storage.id,
                    quantity:
                      storage.quantity -
                      total_required_materials[mat.material.id],
                  });
                  quantity = 0;
                  return;
                  // else go to the next storage and check the remaining quantity
                } else {
                  // we consider taking all materials in this storage
                  totalPrice += storage.unitPrice * storage.quantity;
                  log.push({
                    storageId: storage.id,
                    unitPrice: storage.unitPrice,
                    quantity: storage.quantity,
                    remaining: 0,
                  });
                  storages_to_update.push({
                    id: storage.id,
                    quantity: 0,
                  });
                  quantity = quantity - storage.quantity;
                }
              }
            });
            // this should not execute though
            if (quantity > 0) {
              totalPrice += mat.unitPrice * quantity;
              log.push({
                storageId: "-",
                unitPrice: mat.unitPrice,
                quantity: quantity,
              });
            }
            console.log(log);
            return {
              id: mat.material.id,
              name: mat.material.name,
              unit: mat.material.unit,
              quantity: mat.quantity,
              unitPrice,
              totalPrice,
              log: JSON.stringify(log),
            };
          });
          const labor = item.labors.map((lab) => {
            if (lab.quantity > lab.requiredQuantity) {
              setError(
                `Only ${lab.requiredQuantity} ${lab.labor.type} available`
              );
              error = true;
              return;
            }
            return {
              ...lab.labor,
              quantity: lab.quantity,
              payPerWorker: lab.unitPrice,
            };
          });
          console.log(equipment);
          console.log(materials);
          console.log(labor);
          item.checklists.map((ch) => {
            if (ch.isChecked) {
              checkedChecklists.push(ch.id);
            }
          });
          item.materials.map((material) => {
            if (!error) {
              selectedMilestone.materials.map((mat) => {
                if (mat.id === material.id) {
                  if (
                    total_required_materials[material.material.id] >
                    mat.availableAmount
                  ) {
                    setError(
                      `${
                        mat.availableAmount > 0
                          ? "Only " + mat.availableAmount
                          : "0"
                      } ${material.material.unit} ${
                        material.material.name
                      } left in storage`
                    );
                    error = true;
                  }
                }
              });
            }
          });
          return {
            milestoneId: item.id,
            projectId: currentProject.id,
            checklist: JSON.stringify(item.checklists),
            tasks: JSON.stringify(item.quantifiable_tasks),
            materials: JSON.stringify(materials),
            equipments: JSON.stringify(equipment),
            labor: JSON.stringify(labor),
            notes: notes || null,
            submitted_at: date,
          };
        });
        if (error) {
          return;
        }
        console.log(objects);
        // return;
        const response = await insertReport({
          variables: {
            objects,
          },
        });
        if (
          !insertReportError &&
          response.data.insert_projects_report.affected_rows
        ) {
          storages_to_update.map(async (storage) => {
            await updateQuantity({
              variables: {
                id: storage.id,
                quantity: storage.quantity,
              },
            });
          });
          await setChecked({
            variables: {
              _in: checkedChecklists,
            },
          });
          navigate("/report", {
            state: {
              success: "Report added successfully",
            },
          });
        }
      } else {
        if (!currentProject.id) {
          setError("Current project not found");
        }
        if (!selectedMilestone.id) {
          setError("No milestone selected");
        }
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  return (
    <>
      {selectedMilestone ? (
        <div ref={viewRef} className="col-span-8">
          <div className="flex justify-between items-center w-full">
            <h1 className="font-semibold mt-3">{selectedMilestone?.name}</h1>
          </div>
          <div className="flex w-full">
            <div className="flex flex-col w-1/2">
              <Card className="mt-2 shadow-sm">
                <div className="">
                  <h3 className="font-medium">Checklist</h3>
                  <div className="space-y-2 mt-2">
                    {selectedMilestone.checklists.map((checklistItem, index) =>
                      checklistItem.isChecked && !checklistItem.tempChecked ? (
                        <p
                          key={checklistItem.id}
                          className="text-primary flex items-center"
                        >
                          <CheckCircle className="mr-2 !w-4 !h-4" />
                          {checklistItem.title}
                        </p>
                      ) : (
                        <Checkbox
                          id={checklistItem.id}
                          key={checklistItem.id}
                          checked={checklistItem.isChecked}
                          onClick={(checked) =>
                            changeChecklistIsChecked(checklistItem.id, checked)
                          }
                          label={checklistItem.title}
                        />
                      )
                    )}
                  </div>
                </div>
              </Card>
              <Card className="mt-2 shadow-sm">
                <h3 className="font-medium">Tasks</h3>
                <div className="flex flex-col mt-4">
                  {selectedMilestone.quantifiable_tasks.map((item, index) => (
                    <span key={index} className={`flex items-center mb-2`}>
                      {item.completedPercentage < 100 && (
                        <span className="flex items-center">
                          <Input
                            min="0"
                            placeholder="Qnty"
                            className="w-20"
                            type="number"
                            value={item.quantity}
                            max={item.remainingQuantity}
                            error={item.quantity > item.requiredQuantity}
                            onChange={(el) => {
                              taskChangeHandler(index, el.target.value);
                            }}
                          />
                          <span className="ml-2 min-w-[40px] font-semibold whitespace-nowrap">
                            {item.unit}
                          </span>
                        </span>
                      )}
                      <span className="ml-3 flex flex-col">
                        <span
                          className={`break-words font-medium ${
                            item.completedPercentage >= 100 && "text-success"
                          }`}
                        >
                          {item.name}
                          {item.completedPercentage >= 100 && (
                            <VerifiedIcon className="ml-2 !w-4 !h-4" />
                          )}
                        </span>
                        <span className="text-[10px] text-dark-gray leading-3">
                          {item.remainingQuantity}/{item.requiredQuantity}{" "}
                          {item.unit} remaining
                        </span>
                        <span className="text-[10px] text-dark-gray leading-3">
                          {item.completedPercentage}% completed
                        </span>
                      </span>
                    </span>
                  ))}
                </div>
              </Card>
            </div>
            <div className="flex flex-col ml-4 w-1/2">
              <Card className="mt-2 shadow-sm">
                <div className="">
                  <h3 className="font-medium">Materials used</h3>
                  <div className="flex flex-col mt-4">
                    {selectedMilestone.materials.map((item, index) => (
                      <span key={index} className={`flex items-center mb-2`}>
                        <span className="flex items-center">
                          <Input
                            disabled={item.availableAmount === 0}
                            min="0"
                            placeholder="Qnty"
                            className="w-20"
                            type="number"
                            value={item.quantity}
                            max={item.availableAmount}
                            error={item.quantity > item.availableAmount}
                            onChange={(el) => {
                              materialsChangeHandler(index, el.target.value);
                            }}
                          />
                          <span className="ml-2 font-semibold min-w-[40px] whitespace-nowrap">
                            {item.material.unit}
                          </span>
                        </span>
                        <span className="ml-3 flex flex-col">
                          <span className="break-words font-medium">
                            {item.material.name}
                          </span>
                          <span className="text-[10px] text-dark-gray leading-3">
                            {item.availableAmount} left in storage
                          </span>
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <h3 className="font-medium mt-6">Equipments used</h3>
                <div className="flex flex-col mt-4">
                  {selectedMilestone.equipments.map((item, index) => (
                    <span key={index} className={`flex items-center mb-2`}>
                      <span className="flex items-center">
                        <Input
                          min="0"
                          placeholder="Qnty"
                          className="w-20"
                          type="number"
                          value={item.quantity}
                          // max={item.requiredQuantity}
                          error={item.quantity > item.requiredQuantity}
                          onChange={(el) =>
                            equipmentsChangeHandler(index, el.target.value)
                          }
                        />
                        <span className="ml-2 font-semibold min-w-[40px] whitespace-nowrap">
                          {item.equipment.unit}
                        </span>
                      </span>
                      <span className="ml-3 flex flex-col">
                        <span className="break-words font-medium">
                          {item.equipment.name}
                        </span>
                        <span className="text-[10px] text-dark-gray leading-3">
                          {item.requiredQuantity} {item.equipment.unit}{" "}
                          available
                        </span>
                        {/* <span className="text-[10px] text-dark-gray leading-3">
                          {item.completedPercentage}% completed
                        </span> */}
                      </span>
                    </span>
                  ))}
                </div>
                <h3 className="font-medium mt-6">Laborers worked</h3>
                <div className="flex flex-col mt-4">
                  {selectedMilestone.labors.map((item, index) => (
                    <span key={index} className={`flex items-center mb-2`}>
                      <span className="flex items-center">
                        <Input
                          min="0"
                          placeholder="Qnty"
                          className="w-20"
                          type="number"
                          value={item.quantity}
                          // max={item.requiredQuantity}
                          error={item.quantity > item.requiredQuantity}
                          onChange={(el) =>
                            laborChangeHandler(index, el.target.value)
                          }
                        />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <span className="break-words font-medium">
                          {item.labor.type}
                        </span>
                        <span className="text-[10px] text-dark-gray leading-3">
                          {item.requiredQuantity} {item.labor.unit} available
                        </span>
                        {/* <span className="text-[10px] text-dark-gray leading-3">
                          {item.completedPercentage}% completed
                        </span> */}
                      </span>
                    </span>
                  ))}
                </div>
              </Card>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => {
                navigate("/report");
              }}
              className="mr-3"
              type="light"
            >
              Cancel
            </Button>
            <Button
              // disabled={!formIsValid}
              isLoading={isReportSubmitting}
              onClick={submitHandler}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <p className="font-medium col-span-8 self-center justify-self-center">
          No milestone selected
        </p>
      )}
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
    </>
  );
};

export default Active;
