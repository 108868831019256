import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "../components/UI/Tabs";
import Card from "../components/UI/Card";
import storage from "../assets/illustrations/storage.svg";
import Table from "../components/UI/Table";
import ListSkeleton from "../components/UI/skeleton/ListSkeleton";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { Warning } from "@mui/icons-material";
import Toast from "../components/UI/Toast";
import { useProject } from "../store/project-context";
import NoProject from "../components/UI/NoProject";
import Checkbox from "../components/UI/Checkbox";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
// import { removeDuplicates } from "../functions/extra";
import AddIcon from "@mui/icons-material/Add";
import Button from "../components/UI/Button";
import NewStorage from "../components/Forms/NewStorage";
import { useAuthUser } from "react-auth-kit";

const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { created_at: desc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      name
      milestone_materials {
        quantity
        unitPrice
        material {
          id
          name
          # unitPrice
          unit
          storages(order_by: { date: desc }) {
            created_at
            id
            unitPrice
            quantity
            original_quantity
            status
            date
            notes
            milestone {
              name
            }
          }
          milestone_materials_aggregate {
            aggregate {
              sum {
                quantity
                unitPrice
              }
            }
          }
        }
      }
      reports(where: { inactive: { _neq: true } }) {
        materials
      }
    }
  }
`;

const GET_MATERIALS = gql`
  query MyQuery($projectId: uuid!) {
    projects_materials(
      where: {
        _or: [
          { projectId: { _eq: $projectId } }
          { projectId: { _is_null: true } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      milestone_materials {
        quantity
      }
      name
      id
      unitPrice
      unit
      projectId
      storages {
        quantity
      }
    }
  }
`;
const StoragePage = () => {
  const [newStorageModalIsShown, setNewStorageModalIsShown] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [allActivities, setAllActivities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [incoming, setIncoming] = useState([]);
  const [outgoing, setOutgoing] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const cardRef = useRef();
  const tabRef = useRef();
  const incomingRef = useRef();
  const outgingRef = useRef();
  const auth = useAuthUser();
  const user = auth();
  const { currentProject } = useProject();
  const {
    data,
    loading: getMilestonesLoading,
    error: getMilestonesError,
    refetch: refetchMilestones,
  } = useQuery(GET_MILESTONES, {
    variables: { projectId: currentProject?.id },
  });
  const response = useQuery(GET_MATERIALS, {
    variables: { projectId: currentProject?.id },
  });
  useEffect(() => {
    setSelectedItem(null);
    setSelectedActivity(null);
    async function fetchData() {
      if (currentProject?.id && !getMilestonesLoading) {
        if (data?.projects_milestones) {
          let materials = [];
          let report_materials = [];
          let incoming = [];
          let outgoing = [];
          let allActivities = [];
          let addedMaterials = [];
          let materialCosts = {};
          data?.projects_milestones?.map((milestone) => {
            milestone.milestone_materials?.map((milestone_material) => {
              // to calculate remaining cost
              materialCosts[milestone_material.material.id] =
                (materialCosts[milestone_material.material.id] || 0) +
                milestone_material.quantity * milestone_material.unitPrice;

              if (
                addedMaterials.indexOf(milestone_material.material.id) === -1
              ) {
                addedMaterials.push(milestone_material.material.id);
                materials.push(milestone_material.material);
              }
            });
            milestone.reports?.map((report) => {
              report_materials.push(JSON.parse(report.materials));
            });
          });
          let total_materials = [];
          console.log(materialCosts);
          console.log(materials);
          materials.map((material) => {
            console.log(material);

            if (material.storages.length) {
              let amountBought = 0;
              let amountAvailable = 0;
              let budgetSpent = 0;
              let outgoingQuantity = 0;
              let incomingQuantity = 0;
              let incomingCost = 0;
              material.storages.map((storage) => {
                amountBought += storage.original_quantity;
                amountAvailable += storage.quantity;
                budgetSpent += storage.unitPrice * storage.original_quantity;
                // activities
                if (storage.status === "INCOMING") {
                  incomingQuantity += storage.original_quantity;
                  incomingCost += storage.unitPrice * storage.original_quantity;
                  const obj = {
                    id: storage.id,
                    status: (
                      <span>
                        <ArrowCircleRightIcon className="mr-1 text-primary/70" />
                        Incoming
                      </span>
                    ),
                    name: material.name,
                    quantity: storage.original_quantity,
                    price: storage.unitPrice,
                    phase: storage.milestone ? storage.milestone.name : "-",
                    date: moment(storage.date).format("LL"),
                    type: "INCOMING",
                    unit: material.unit,
                    notes: storage.notes,
                  };
                  incoming.push(obj);
                  allActivities.push(obj);
                } else {
                  outgoingQuantity += storage.original_quantity;
                  const obj = {
                    id: storage.id,
                    status: (
                      <span>
                        <ArrowCircleLeftIcon className="mr-1 text-dark-red/70" />
                        Outgoing
                      </span>
                    ),
                    name: material.name,
                    quantity: storage.original_quantity,
                    price: storage.unitPrice,
                    phases: storage.milestone ? storage.milestone.name : "-",
                    date: moment(storage.date).format("LL"),
                    unit: material.unit,
                    type: "OUTGOING",
                    notes: storage.notes,
                  };
                  outgoing.push(obj);
                  allActivities.push(obj);
                }
              });
              console.log(amountBought, budgetSpent);
              if (report_materials.length > 0) {
                // since a material might have multiple reports
                report_materials.map((report_material) => {
                  report_material.map((inner_rep_mat) => {
                    if (material.id === inner_rep_mat.id) {
                      console.log(
                        material.milestone_materials_aggregate.aggregate.sum
                          .unitPrice
                      );
                      let found = false;
                      total_materials.map((mat) => {
                        if (mat.id === inner_rep_mat.id) {
                          mat.amountUsed =
                            parseInt(mat.amountUsed) +
                            parseInt(inner_rep_mat.quantity);
                          // mat.amountNeeded =
                          //   (material.milestone_materials_aggregate.aggregate
                          //     .sum.quantity || 0) - mat.amountUsed;
                          found = true;
                          console.log(mat.amountUsed);
                          return;
                        }
                      });
                      if (!found) {
                        const milestoneNames = material.storages.map(
                          (storage) => storage?.milestone?.name
                        );

                        let phases = milestoneNames
                          .filter(
                            (item, index) =>
                              milestoneNames.indexOf(item) === index
                          )
                          .filter(function (element) {
                            return element !== undefined;
                          })
                          .join(", ");
                        total_materials.push({
                          ...inner_rep_mat,
                          amountUsed: inner_rep_mat.quantity,
                          // amountNeeded: material.quantity,
                          amountBought,
                          amountAvailable,
                          amountNeeded:
                            (material.milestone_materials_aggregate.aggregate
                              .sum.quantity || 0) - amountBought,
                          // outgoingTotalCost: (
                          //   inner_rep_mat.quantity * inner_rep_mat.unitPrice
                          // ).toLocaleString(),
                          incomingCost,
                          incomingQuantity,
                          original_unit_price: material.unitPrice,
                          outgoingQuantity,
                          phases,
                          budgetSpent,
                          remainingCost:
                            materialCosts[material.id] - budgetSpent,
                          // remainingCost: (
                          //   (material.unitPrice *
                          //     (material.milestone_materials_aggregate.aggregate
                          //       .sum.quantity || 0)) -
                          //   budgetSpent
                          // ).toLocaleString(),
                          description: material.storages[0]?.description,
                          date: material.storages[material.storages.length - 1]
                            ?.date,
                        });
                      }
                    }
                  });
                });
              } else {
                const milestoneNames = material.storages.map(
                  (storage) => storage?.milestone?.name
                );

                let phases = milestoneNames
                  .filter(
                    (item, index) => milestoneNames.indexOf(item) === index
                  )
                  .filter(function (element) {
                    return element !== undefined;
                  })
                  .join(", ");

                total_materials.push({
                  // ...inner_rep_mat,
                  id: material.id,
                  name: material.name,
                  amountUsed: 0,
                  // amountNeeded: material.quantity,
                  amountBought,
                  amountAvailable,
                  original_unit_price: material.unitPrice,
                  amountNeeded:
                    material.milestone_materials_aggregate.aggregate.sum
                      .quantity || 0, // every quantity in milestone_materials
                  incomingCost,
                  incomingQuantity,
                  outgoingQuantity,
                  phases,
                  budgetSpent,
                  remainingCost: materialCosts[material.id] - budgetSpent,
                  // remainingCost: (
                  //   material.unitPrice *
                  //     (material.milestone_materials_aggregate.aggregate.sum
                  //       .quantity || 0) -
                  //   budgetSpent
                  // ).toLocaleString(),
                  description: material.storages[0]?.description,
                  date: material.storages[material.storages.length - 1]?.date,
                });
              }
            }
          });
          console.log(total_materials);
          setMaterials(total_materials);
          setActivities([...outgoing, ...incoming]);
          setAllActivities(allActivities);
          setOutgoing(outgoing);
          setIncoming(incoming);
        }
      }
    }
    fetchData();
  }, [currentProject?.id, data?.projects_milestones]);
  console.log(materials);
  const materialsFiltered = materials?.map((material) => {
    return {
      id: material.id,
      name: material.name,
      amountUsed: material.amountUsed,
      amountBought: material.amountBought,
      amountAvailable: material.amountAvailable,
      date: moment(material.date).format("LL"),
    };
  });
  const hideModal = () => {
    setSelectedItem(null);
    setEditMode(false);
    setNewStorageModalIsShown(false);
  };
  const selectMaterial = (id) => {
    if (id === null) {
      setSelectedItem(null);
      return;
    }
    materials?.map((material) => {
      if (material.id === id) {
        setSelectedItem(material);
        return material;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const selectActivity = (id) => {
    if (id === null) {
      setSelectedActivity(null);
      return;
    }
    allActivities?.map((activity) => {
      if (activity.id === id) {
        console.log(activity);
        setSelectedActivity(activity);
        return activity;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const changeDisplayedData = (status) => {
    console.log(incomingRef.current.checked, outgingRef.current.checked);
    if (
      (incomingRef.current.checked && outgingRef.current.checked) ||
      (!incomingRef.current.checked && !outgingRef.current.checked)
    ) {
      setActivities(allActivities);
      return;
    }
    if (status === "incoming") {
      if (incomingRef.current.checked) {
        setActivities(incoming);
      } else {
        setActivities(outgoing);
      }
    } else if (status === "outgoing") {
      if (outgingRef.current.checked) {
        setActivities(outgoing);
      } else {
        setActivities(incoming);
      }
    }
  };
  const headers = [
    "Name",
    "Used amount",
    "Amount bought",
    "Available",
    "Last added date",
  ];
  const activitiesHeaders = [
    "In/out",
    "Material",
    "Quantity",
    "Price",
    "Phase",
    "Date",
  ];
  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 grid grid-cols-12">
        <Tabs
          tabRef={tabRef}
          className={`${
            selectedItem || selectedActivity ? "col-span-9" : "col-span-12"
          }`}
          otherElements={
            user.roles.find(
              (role) => role.code === "ST_KP" || role.code === "ADM"
            ) && (
              <Button
                onClick={() => {
                  setNewStorageModalIsShown(true);
                }}
              >
                <AddIcon />
                New Activity
              </Button>
            )
          }
          callback={(activeTab) => {
            if (activeTab === 0) {
              setSelectedActivity(null);
            }
            if (activeTab === 1) {
              setSelectedItem(null);
            }
          }}
        >
          <Tab label="Materials">
            <div className="">
              {getMilestonesLoading && !getMilestonesError ? (
                <ListSkeleton />
              ) : getMilestonesError ? (
                <p className="col-span-full mt-5 flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {getMilestonesError?.message}
                </p>
              ) : materials?.length ? (
                <Table
                  title={`All materials(${materials?.length || 0})`}
                  search={true}
                  searchList={materials}
                  searchPropertiesKey="name"
                  searchPlaceholder="Search a material"
                  checkboxClickHandler={selectMaterial}
                  headers={headers}
                  rows={materialsFiltered}
                  errorMessage={
                    getMilestonesError && (
                      <p className="flex justify-center items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {getMilestonesError?.message}
                      </p>
                    )
                  }
                />
              ) : (
                <div className="w-full h-full space-y-3 mt-4 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    src={storage}
                    alt="Material details illustration"
                  />
                  <p className="text-gray-700 text-2xl mt-5">
                    No materials found
                  </p>
                  <p className="text-dark-gray text-sm">
                    Materials will show up here once created by the client
                  </p>
                </div>
              )}
            </div>
          </Tab>
          <Tab label="Activities">
            <div className="">
              <div className="">
                <Table
                  otherProp={
                    <div className="flex space-x-6 mt-3">
                      <Checkbox
                        chbxRef={incomingRef}
                        onClick={() => {
                          changeDisplayedData("incoming");
                        }}
                        id="incoming"
                        label="Incoming"
                      />
                      <Checkbox
                        chbxRef={outgingRef}
                        onClick={() => {
                          changeDisplayedData("outgoing");
                        }}
                        id="outgoing"
                        label="Outgoing"
                      />
                    </div>
                  }
                  // search={true}
                  // searchList={removeDuplicates(activities, "name")}
                  // searchPropertiesKey="name"
                  // searchPlaceholder="Search a material"
                  checkboxClickHandler={selectActivity}
                  headers={activitiesHeaders}
                  rows={activities}
                  errorMessage={
                    getMilestonesError && (
                      <p className="flex justify-center items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {getMilestonesError?.message}
                      </p>
                    )
                  }
                />
              </div>
            </div>
          </Tab>
        </Tabs>
        {!getMilestonesError && (
          <>
            {!selectedActivity && (
              <Card
                // cardRef={cardRef}
                className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
                  selectedItem
                    ? "translate-x-0 col-span-3"
                    : "translate-x-full !bg-transparent"
                }`}
              >
                {selectedItem ? (
                  <Fragment>
                    <h1 className="text-start w-full text-lg font-medium">
                      Material details
                    </h1>
                    <ul className="w-full text-xs space-y-3 p-2">
                      <li className="flex flex-col justify-between">
                        <h3 className="text-gray-700 text-base font-semibold">
                          {selectedItem.name}
                        </h3>
                      </li>
                      <li className="flex mt-4 justify-between">
                        <span className="text-dark-gray">Amount needed</span>
                        <span className="text-gray-700 text-right">
                          {selectedItem.amountNeeded >= 0 ? (
                            <>
                              <span className="font-semibold mr-1">
                                {selectedItem.amountNeeded.toLocaleString()}
                              </span>
                              {selectedItem.unit}
                            </>
                          ) : (
                            <span className="text-right">
                              0 <br />
                              <span className="text-[10px] font-semibold text-dark-red">
                                (
                                {Math.abs(
                                  selectedItem.amountNeeded
                                ).toLocaleString()}{" "}
                                {selectedItem.unit} extra)
                              </span>
                            </span>
                          )}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Amount bought</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.amountBought.toLocaleString()}
                          </span>
                          {selectedItem.unit}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Used amount</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.amountUsed.toLocaleString()}
                          </span>
                          {selectedItem.unit}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">
                          Available in storage
                        </span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.amountAvailable.toLocaleString()}
                          </span>
                          {selectedItem.unit}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Budget spent</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.budgetSpent.toLocaleString()}
                          </span>
                          ETB
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Remaining cost</span>
                        <span className="text-gray-700 text-right">
                          {selectedItem.remainingCost >= 0 ? (
                            <>
                              <span className="font-semibold mr-1">
                                {selectedItem.remainingCost.toLocaleString()}
                              </span>
                              ETB
                            </>
                          ) : (
                            <span className="text-right">
                              0 <br />
                              <span className="text-[10px] font-semibold text-dark-red">
                                (
                                {Math.abs(
                                  selectedItem.remainingCost
                                ).toLocaleString()}{" "}
                                ETB overspent)
                              </span>
                            </span>
                          )}
                        </span>
                      </li>
                      <hr />
                      <h4 className="text-gray-700 text-sm font-semibold">
                        Storage activity
                      </h4>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Last added date</span>
                        <span className="text-gray-700 font-semibold mr-1">
                          {moment(selectedItem.date).format("LL")}
                        </span>
                      </li>
                      <h4 className="text-gray-700 text-sm font-medium">
                        Incoming
                      </h4>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Quantity</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.incomingQuantity.toLocaleString()}
                          </span>
                          {selectedItem.unit}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Total cost</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.incomingCost.toLocaleString()}
                          </span>
                          ETB
                        </span>
                      </li>
                      <h4 className="text-gray-700 text-sm font-medium">
                        Outgoing
                      </h4>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Quantity</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.outgoingQuantity.toLocaleString()}
                          </span>
                          {selectedItem.unit}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Phase(s)</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedItem.phases || "-"}
                          </span>
                        </span>
                      </li>
                    </ul>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
              </Card>
            )}
            {!selectedItem && (
              <Card
                // cardRef={cardRef}
                className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
                  selectedActivity
                    ? "translate-x-0 col-span-3"
                    : "translate-x-full !bg-transparent"
                }`}
              >
                {selectedActivity ? (
                  <Fragment>
                    <h1 className="text-start w-full text-lg font-medium">
                      Activity details
                    </h1>
                    <ul className="w-full text-xs space-y-3 p-2">
                      <li className="flex flex-col justify-between">
                        <h3 className="text-gray-700 text-base font-semibold">
                          {selectedActivity.name}
                        </h3>
                      </li>
                      <li className="flex mt-4 justify-between">
                        <span className="text-dark-gray">Type</span>
                        <span className="text-gray-700 font-semibold text-right capitalize">
                          {selectedActivity.type.toLowerCase()}
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Date</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedActivity.date}
                          </span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Price</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedActivity.price.toLocaleString()} ETB
                          </span>
                        </span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Quantity</span>
                        <span>
                          <span className="text-gray-700 font-semibold mr-1">
                            {selectedActivity.quantity.toLocaleString()}
                          </span>
                          {selectedActivity.unit}
                        </span>
                      </li>
                      {selectedActivity.type === "OUTGOING" && (
                        <li className="flex justify-between">
                          <span className="text-dark-gray">Phase</span>
                          <span>
                            <span className="text-gray-700 font-semibold mr-1">
                              {selectedActivity.phases}
                            </span>
                          </span>
                        </li>
                      )}
                      <li className="flex justify-between">
                        <span className="text-dark-gray">Note</span>
                        <span className="text-gray-700 text-right">
                          {selectedActivity.notes || "-"}
                        </span>
                      </li>
                    </ul>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
              </Card>
            )}
          </>
        )}
        {newStorageModalIsShown &&
          user.roles.find(
            (role) => role.code === "ST_KP" || role.code === "ADM"
          ) && (
            <NewStorage
              refetch={refetchMilestones}
              setSuccess={setSuccess}
              setError={setError}
              allMaterials={response.data?.projects_materials}
              milestones={data?.projects_milestones}
              isShown={newStorageModalIsShown}
              hideModal={hideModal}
              selectedStorage={selectedItem}
              editMode={editMode}
            />
          )}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default StoragePage;
