import React from "react";
import { Check2, ExclamationLg } from "react-bootstrap-icons";

const Stepper = (props) => {
  const { items, activeStep, onClick } = props;
  return (
    <ol className="space-y-6 cursor-pointer text-gray-500 dark:border-gray-700 dark:text-gray-400">
      {items?.map((item, index) => (
        <li
          onClick={() => onClick(index + 1)}
          key={index}
          className="flex items-center justify-center group w-fit"
        >
          <span
            className={`shadow-lg group-hover:ring-secondary/40  font-semibold flex items-center justify-center mr-3 w-10 h-10 rounded-full ring-2 ring-white dark:ring-gray-900 dark:bg-green-900 ${
              index + 1 <= activeStep
                ? "bg-secondary text-white ring-secondary/40"
                : "bg-gray-100 group-hover:text-secondary/70"
            } ${index + 1 < activeStep && "ring-secondary"}`}
          >
            {activeStep > index + 1 ? (
              item.isValid ? (
                <Check2 className="w-6 h-6" />
              ) : (
                <ExclamationLg className="w-6 h-6" />
              )
            ) : (
              index + 1
            )}
          </span>
          <h3
            className={`font-medium text-sm leading-tight group-hover:text-secondary/70  ${
              index + 1 === activeStep && "text-secondary"
            }`}
          >
            {item.name}
          </h3>
          {item.desc && <p className="text-sm">{item.desc}</p>}
        </li>
      ))}
    </ol>
  );
};

export default Stepper;
