import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "../../components/UI/Tabs";
import Card from "../../components/UI/Card";
import IconButton from "../../components/UI/IconButton";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import Toast from "../../components/UI/Toast";
import { useProject } from "../../store/project-context";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import { Calendar2, ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { Warning } from "@mui/icons-material";
import DatePicker from "../../components/UI/DatePicker";
import noReportFound from "../../assets/illustrations/noReportFound.svg";
import inactive from "../../assets/illustrations/engineer.svg";
import NoProject from "../../components/UI/NoProject";

const GET_REPORT = gql`
  query MyQuery($from: timestamptz!, $to: timestamptz!, $projectId: uuid!) {
    projects_report(
      where: {
        submitted_at: { _gte: $from, _lte: $to }
        projectId: { _eq: $projectId }
      }
    ) {
      checklist
      submitted_at
      equipments
      id
      labor
      materials
      milestoneId
      tasks
      inactive
      notes
      reason
      milestone {
        name
      }
    }
  }
`;

const GET_INACTIVE_DAYS = gql`
  query MyQuery($to: timestamptz!, $from: timestamptz!, $projectId: uuid!) {
    projects_report(
      where: {
        submitted_at: { _lte: $to, _gte: $from }
        projectId: { _eq: $projectId }
        inactive: { _eq: true }
      }
    ) {
      submitted_at
      inactive
      reason
    }
  }
`;

const ReportPage = () => {
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { currentProject } = useProject();
  const location = useLocation();
  const from = new Date(date);
  from.setHours(0, 0, 0, 0);
  const to = new Date(date);
  to.setHours(23, 59, 59, 999);
  const {
    data: report,
    loading: reportLoading,
    error: reportError,
    refetch: refetchReport,
  } = useQuery(GET_REPORT, {
    variables: { projectId: currentProject?.id, from, to },
    fetchPolicy: "network-only",
  });
  const [
    getInactiveDays,
    {
      data: inactiveDays,
      loading: inactiveDaysLoading,
      error: inactiveDaysError,
      // refetch: refetchImages,
    },
  ] = useLazyQuery(GET_INACTIVE_DAYS, { fetchPolicy: "network-only" });
  useEffect(() => {
    getInactiveDays({
      variables: {
        projectId: currentProject?.id,
        from: new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substring(0, 10),
        to: new Date(),
      },
    });
  }, [currentProject]);
  console.log(report);
  useEffect(() => {
    if (location?.state?.success) {
      setSuccess(location?.state?.success);
      window.history.replaceState({}, document.title);
    }
    refetchReport();
  }, [refetchReport, location?.state?.success]);
  const dateChangeHandler = (date) => {
    console.log(date);
    setDate(date);
  };
  const addOneDay = (date) => {
    let date2 = new Date(date.getTime());
    date2.setDate(date.getDate() + 1);
    setDate(date2);
  };
  const subtractOneDay = (date) => {
    let date2 = new Date(date.getTime());
    date2.setDate(date.getDate() - 1);
    setDate(date2);
  };
  const isToday = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  useEffect(() => {
    if (location?.state?.success) {
      setSuccess(location?.state?.success);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.success]);
  let totalEquipmentCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  report?.projects_report?.map((rep) => {
    if (!rep.inactive) {
      JSON.parse(rep.materials).map((item) => {
        totalMaterialCost += item.totalPrice;
      });
      JSON.parse(rep.equipments).map((item) => {
        totalEquipmentCost += item.unitPrice * item.quantity;
      });
      JSON.parse(rep.labor).map((item) => {
        totalLaborCost += item.quantity * item.payPerWorker;
      });
    }
  });
  const totalCost = totalMaterialCost + totalEquipmentCost + totalLaborCost;
  if (currentProject) {
    return (
      <div className="m-3 grid grid-cols-12">
        <Tabs
          className="col-span-9"
          otherElements={
            <Button
              onClick={() => {
                navigate("/report/new");
              }}
            >
              <AddIcon />
              New Report
            </Button>
          }
        >
          <Tab label="All reports">
            <div>
              <div className="flex justify-between">
                <span className="flex items-center space-x-1">
                  <IconButton
                    type="rounded"
                    className="border-none"
                    onClick={() => {
                      subtractOneDay(date);
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <span className="text-sm text-center w-28">
                    {isToday(new Date(), date)
                      ? "Today"
                      : moment(date).format("ll")}
                  </span>
                  <IconButton
                    type="rounded"
                    className="border-none"
                    onClick={() => {
                      addOneDay(date);
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </span>
                <DatePicker
                  value={date}
                  otherClasses="border border-gray-300"
                  className="!w-44"
                  icon={<Calendar2 />}
                  onChange={dateChangeHandler}
                  dateFormat="dd/MM/yyyy"
                  id="start-date"
                  placeholderText={moment().format("L")}
                />
              </div>
              <div className="bg-white text-sm mt-4 p-4">
                <h1 className="font-medium">
                  Daily report - {moment(date).format("ll")}
                </h1>
                <div className="flex space-x-5 w-full">
                  {reportLoading && !reportError ? (
                    <p className="w-full my-5 text-center">Loading...</p>
                  ) : reportError ? (
                    <p className="w-full my-5 flex justify-center items-center">
                      <Warning className="!w-4 !h-4 mr-1" />
                      {reportError?.message}
                    </p>
                  ) : report?.projects_report?.length === 0 ? (
                    <div className="flex flex-col items-center mt-6 w-full">
                      <img
                        className="w-1/4"
                        src={noReportFound}
                        alt="No report found"
                      />
                      <p className="mt-5 text-medium text-xl">
                        No report submitted for this day
                      </p>
                    </div>
                  ) : report?.projects_report[0]?.inactive ? (
                    <div className="flex flex-col items-center mt-6 w-full">
                      <img
                        className="w-1/4"
                        src={inactive}
                        alt="No report found"
                      />
                      <p className="mt-5 text-medium text-xl">
                        {report?.projects_report[0].reason}
                      </p>
                      <p className="mt-2 text-sm max-w-xl text-left">
                        {report?.projects_report[0].notes}
                      </p>
                    </div>
                  ) : (
                    <>
                      <Card className="mt-4 w-1/2 shadow-md space-y-4">
                        {report?.projects_report?.map((rep, index) => (
                          <div
                            key={index}
                            className={`${index !== 0 && "border-t pt-4"}`}
                          >
                            <h2 className="text-sm font-bold mb-4">
                              {rep.milestone?.name}
                            </h2>
                            <h4 className="font-semibold my-2">Tasks Done</h4>
                            <ul className="ml-10 list-disc">
                              {JSON.parse(rep.tasks)?.map((task, index) => (
                                <li key={index}>
                                  <span className="font-semibold mr-2">
                                    {task.quantity} {task.unit}
                                  </span>
                                  <span className="lowercase">{task.name}</span>
                                </li>
                              ))}
                            </ul>
                            {(JSON.parse(rep.materials)?.length > 0 &&
                              JSON.parse(rep.materials).find(
                                (item) => item.quantity > 0
                              )) ||
                            (JSON.parse(rep.labor)?.length > 0 &&
                              JSON.parse(rep.labor).find(
                                (item) => item.quantity > 0
                              )) ||
                            (JSON.parse(rep.equipments)?.length > 0 &&
                              JSON.parse(rep.equipments).find(
                                (item) => item.quantity > 0
                              )) ? (
                              <h4 className="font-semibold mt-4 my-2">
                                Resources Used
                              </h4>
                            ) : (
                              <h4 className="font-semibold mt-4 my-2">
                                No resources used
                              </h4>
                            )}
                            <ul className="ml-10 list-disc">
                              {JSON.parse(rep.materials)?.length > 0 &&
                                JSON.parse(rep.materials).find(
                                  (item) => item.quantity > 0
                                ) && (
                                  <span className="text-[10px] text-dark-gray">
                                    Materials
                                  </span>
                                )}
                              {JSON.parse(rep.materials)?.map(
                                (material, index) =>
                                  material.quantity > 0 && (
                                    <li
                                      key={index}
                                      className="flex justify-between"
                                    >
                                      <span>
                                        <span className="font-semibold mr-2">
                                          {material.quantity} {material.unit}
                                        </span>
                                        <span className="lowercase">
                                          {material.name}
                                        </span>
                                      </span>
                                      <span>
                                        {material.totalPrice.toLocaleString()}{" "}
                                        Br
                                      </span>
                                    </li>
                                  )
                              )}
                              {JSON.parse(rep.labor)?.length > 0 &&
                                JSON.parse(rep.labor).find(
                                  (item) => item.quantity > 0
                                ) && (
                                  <span className="text-[10px] text-dark-gray">
                                    Labor
                                  </span>
                                )}
                              {JSON.parse(rep.labor)?.map(
                                (lab, index) =>
                                  lab.quantity > 0 && (
                                    <li
                                      key={index}
                                      className="flex justify-between"
                                    >
                                      <span>
                                        <span className="font-semibold mr-2">
                                          {lab.quantity}
                                        </span>
                                        <span className="lowercase">
                                          {lab.type}
                                        </span>
                                      </span>
                                      <span>
                                        {(
                                          lab.payPerWorker * lab.quantity
                                        ).toLocaleString()}{" "}
                                        Br
                                      </span>
                                    </li>
                                  )
                              )}
                              {JSON.parse(rep.equipments)?.length > 0 &&
                                JSON.parse(rep.equipments).find(
                                  (item) => item.quantity > 0
                                ) && (
                                  <span className="text-[10px] text-dark-gray">
                                    Equipment
                                  </span>
                                )}
                              {JSON.parse(rep.equipments)?.map(
                                (equipment, index) =>
                                  equipment.quantity > 0 && (
                                    <li
                                      key={index}
                                      className="flex justify-between"
                                    >
                                      <span>
                                        <span className="font-semibold mr-2">
                                          {equipment.quantity} {equipment.unit}
                                        </span>
                                        <span className="lowercase">
                                          {equipment.name}
                                        </span>
                                      </span>
                                      <span>
                                        {(
                                          equipment.unitPrice *
                                          equipment.quantity
                                        ).toLocaleString()}{" "}
                                        Br
                                      </span>
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        ))}
                      </Card>
                      <div className="w-1/2">
                        <Card className="mt-4 shadow-md">
                          <h2 className="text-sm font-bold mb-4">Total Cost</h2>
                          <p className="text-center">
                            <span className="font-medium">
                              {totalCost.toLocaleString()} Br
                            </span>{" "}
                            spent today
                          </p>
                          <table className="w-full mt-2">
                            <tbody>
                              <tr>
                                <td>Materials</td>
                                <td>{totalMaterialCost.toLocaleString()} Br</td>
                                <td>
                                  {totalCost
                                    ? Math.round(
                                        (totalMaterialCost / totalCost) * 100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Human labor</td>
                                <td>{totalLaborCost.toLocaleString()} Br</td>
                                <td>
                                  {totalCost
                                    ? Math.round(
                                        (totalLaborCost / totalCost) * 100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Equipment</td>
                                <td>
                                  {totalEquipmentCost.toLocaleString()} Br
                                </td>
                                <td>
                                  {totalCost
                                    ? Math.round(
                                        (totalEquipmentCost / totalCost) * 100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                        <Card className="mt-4 shadow-md">
                          <h2 className="text-sm font-bold mb-4">Notes</h2>
                          <p
                            className={`${
                              !report?.projects_report[0]?.notes &&
                              "text-dark-gray"
                            }`}
                          >
                            {report?.projects_report[0]?.notes ||
                              "No description about today's work"}
                          </p>
                        </Card>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Tab>
          <Tab label=""></Tab>
        </Tabs>
        <Card className="col-span-3 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3">
          <h1 className="text-start w-full text-lg font-medium">
            Recent inactive days
          </h1>
          {inactiveDaysLoading ? (
            <p className="mt-4 text-center w-full text-xs">Loading...</p>
          ) : inactiveDaysError ? (
            <p className="mt-4 text-center w-full text-xs">
              {inactiveDaysError.message}
            </p>
          ) : inactiveDays && inactiveDays.projects_report?.length ? (
            <table className="w-full text-xs">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {inactiveDays.projects_report?.map((report, index) => (
                  <tr key={index} className="mb-4">
                    <td className="text-start">
                      {moment(report.submitted_at).format("ll")}
                    </td>
                    <td className="text-end">{report.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mt-4 text-center w-full text-xs">No inactive days</p>
          )}
        </Card>
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default ReportPage;
