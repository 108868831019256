import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "../components/UI/Tabs";
import Card from "../components/UI/Card";
import resourcesIllustration from "../assets/illustrations/resources.svg";
import Table from "../components/UI/Table";
import ListSkeleton from "../components/UI/skeleton/ListSkeleton";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Warning } from "@mui/icons-material";
import Toast from "../components/UI/Toast";
import { useProject } from "../store/project-context";
import NoProject from "../components/UI/NoProject";
import Button from "../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import NewMaterial from "../components/Forms/NewMaterial";
import NewEquipment from "../components/Forms/NewEquipment";
import NewLabor from "../components/Forms/NewLabor";
import { useAuthUser } from "react-auth-kit";

const GET_RESOURCES = gql`
  query MyQuery($companyId: uuid!) {
    projects_materials(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      milestone_materials {
        quantity
      }
      name
      id
      unitPrice
      unit
      companyId
      storages {
        quantity
      }
    }
    projects_equipments(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      name
      unit
      unitPrice
      status
      id
      companyId
      milestone_equipments {
        quantity
      }
    }
    projects_labor(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
      }
      order_by: { type: asc }
    ) {
      id
      companyId
      milestone_labors {
        quantity
      }
      payPerWorker
      type
    }
  }
`;

const GET_RESOURCES_ALL = gql`
  query MyQuery {
    projects_materials(
      where: { companyId: { _is_null: true } }
      order_by: { name: asc }
    ) {
      milestone_materials {
        quantity
      }
      name
      id
      unitPrice
      unit
      companyId
      storages {
        quantity
      }
    }
    projects_equipments(
      where: { companyId: { _is_null: true } }
      order_by: { name: asc }
    ) {
      name
      unit
      unitPrice
      status
      id
      companyId
      milestone_equipments {
        quantity
      }
    }
    projects_labor(
      where: { companyId: { _is_null: true } }
      order_by: { type: asc }
    ) {
      id
      companyId
      milestone_labors {
        quantity
      }
      payPerWorker
      type
    }
  }
`;
const ResourcesPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [labor, setLabor] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [cardVisible, setCardVisible] = useState(false);
  // const [isConfirmDeleteOneShown, setIsConfirmDeleteOneShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activeModal, setActiveModal] = useState(-1);
  const { currentProject, company } = useProject();
  const auth = useAuthUser();
  const user = auth();
  const isSuperAdmin = user?.roles?.map((role) => role.code).includes("SU");
  let variables = {};
  const [
    getResources,
    { data: resources, loading: resourcesLoading, error: resourcesError },
  ] = useLazyQuery(isSuperAdmin ? GET_RESOURCES_ALL : GET_RESOURCES);
  useEffect(() => {
    if (!isSuperAdmin && company?.id) {
      console.log(company.id);
      variables = {
        companyId: company?.id,
      };
      getResources({ variables });
    }
    if (isSuperAdmin) {
      getResources();
    }
  }, [company]);
  // const {
  //   data: resources,
  //   loading: resourcesLoading,
  //   error: resourcesError,
  //   refetch,
  // } = useQuery(isSuperAdmin ? GET_RESOURCES_ALL : GET_RESOURCES, {
  //   variables,
  // });

  const cardRef = useRef();
  // const tabRef = useRef();
  // const showDeleteOneConfirmation = () => {
  //   setIsConfirmDeleteOneShown(true);
  // };

  // const [deleteItem, { loading: isDeleting, error: deleteError }] =
  //   useMutation(DELETE_ITEM);

  useEffect(() => {
    async function fetchData() {
      setSelectedItem(null);
      if (currentProject?.id && !resourcesLoading) {
        let materials = [];
        let equipment = [];
        let labor = [];
        // console.log(resources);
        // const project = resources.projects_materials;
        // resources.projects_projects_by_pk.map((project) => {
        materials = resources.projects_materials.map((material) => {
          return {
            id: material.id,
            name: material.name,
            unit: material.unit,
            unitPrice: material.unitPrice,
            quantity: material.quantity,
            // status: material.status,
            // milestone: milestone.name,
          };
        });
        equipment = resources.projects_equipments.map((eq) => {
          return {
            id: eq.id,
            name: eq.name,
            unit: eq.unit,
            unitPrice: eq.unitPrice,
            // quantity: eq.quantity,
            status: eq.status,
            // milestone: milestone.name,
          };
        });
        labor = resources.projects_labor.map((lab) => {
          return {
            id: lab.id,
            type: lab.type,
            payPerWorker: lab.payPerWorker,
            quantity: lab.quantity,
            // status: material.status,
            // milestone: milestone.name,
          };
        });
        // equipment = milestone.equipments;
        // labor = milestone.labors;
        // });
        console.log(materials);
        setMaterials(materials);
        setEquipment(equipment);
        setLabor(labor);
      }
    }
    fetchData();
  }, [currentProject?.id, resources, resourcesLoading]);
  const hideModal = () => {
    setSelectedItem(null);
    setEditMode(false);
    setActiveModal(-1);
  };
  const selectItem = (id) => {
    if (id === null) {
      setSelectedItem(null);
      return;
    }
    materials.map((item) => {
      if (item.id === id) {
        setSelectedItem(item);
        return item;
      } else {
        return;
      }
    });
    equipment.map((item) => {
      if (item.id === id) {
        setSelectedItem(item);
        return item;
      } else {
        return;
      }
    });
    labor.map((item) => {
      if (item.id === id) {
        setSelectedItem(item);
        return item;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
    // tabRef.current && tabRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const materialsHeaders = [
    "Item name",
    "Unit",
    "Unit price",
    // "Quantity available",
  ];
  const equipmentHeaders = [
    "Item name",
    "Unit",
    "Unit price",
    // "Quantity",
    "status",
  ];
  const laborHeaders = [
    "Type",
    "Pay per worker",
    // "Number of workers",
    // "Quantity",
    // "status"
  ];
  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 grid grid-cols-12">
        <Tabs
          // tabRef={tabRef}
          className={`${
            selectedItem || cardVisible ? "col-span-9" : "col-span-12"
          }`}
          otherElements={
            <Button
              onClick={() => {
                setActiveModal(activeTab);
              }}
            >
              <AddIcon />
              New{" "}
              {activeTab === 0
                ? "Material"
                : activeTab === 1
                ? "Equipment"
                : "Labor"}
            </Button>
          }
          callback={(actTab) => {
            if (actTab !== activeTab) {
              setActiveTab(actTab);
              setCardVisible(false);
              setSelectedItem(null);
            }
            // if (activeTab === 0) {
            // } else {
            //   setCardVisible(true);
            // }
          }}
        >
          <Tab label="Materials">
            <div className="">
              {resourcesLoading && !resourcesError ? (
                <ListSkeleton />
              ) : resourcesError ? (
                <p className="flex justify-center mt-5 items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {resourcesError?.message}
                </p>
              ) : materials.length > 0 ? (
                <Table
                  title="Materials"
                  search={true}
                  searchList={materials}
                  searchPropertiesKey="name"
                  searchPlaceholder="Search an item"
                  checkboxClickHandler={selectItem}
                  headers={materialsHeaders}
                  rows={materials}
                  errorMessage={
                    resourcesError && (
                      <p className="flex justify-center mt-5 items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {resourcesError?.message}
                      </p>
                    )
                  }
                />
              ) : (
                <div className="w-full h-full space-y-3 mt-4 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    src={resourcesIllustration}
                    alt="Material details illustration"
                  />
                  <p className="text-gray-700 text-2xl mt-5">
                    No materials found
                  </p>
                  <p className="text-dark-gray text-sm">
                    Materials will show up here once added
                  </p>
                </div>
              )}
            </div>
          </Tab>
          <Tab label="Equipment">
            <div className="">
              {resourcesLoading && !resourcesError ? (
                <ListSkeleton />
              ) : resourcesError ? (
                <p className="flex justify-center mt-5 items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {resourcesError?.message}
                </p>
              ) : equipment.length > 0 ? (
                <Table
                  title="Equipment"
                  search={true}
                  searchList={equipment}
                  searchPropertiesKey="name"
                  searchPlaceholder="Search an item"
                  checkboxClickHandler={selectItem}
                  headers={equipmentHeaders}
                  rows={equipment}
                  errorMessage={
                    resourcesError && (
                      <p className="flex justify-center mt-5 items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {resourcesError?.message}
                      </p>
                    )
                  }
                />
              ) : (
                <div className="w-full h-full space-y-3 mt-4 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    src={resourcesIllustration}
                    alt="Material details illustration"
                  />
                  <p className="text-gray-700 text-2xl mt-5">
                    No equipment found
                  </p>
                  <p className="text-dark-gray text-sm">
                    Equipment will show up here once added
                  </p>
                </div>
              )}
            </div>
          </Tab>
          <Tab label="Labor">
            <div className="">
              {resourcesLoading && !resourcesError ? (
                <ListSkeleton />
              ) : resourcesError ? (
                <p className="flex justify-center mt-5 items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {resourcesError?.message}
                </p>
              ) : labor.length > 0 ? (
                <Table
                  title="Labor"
                  search={true}
                  searchList={labor}
                  searchPropertiesKey="type"
                  searchPlaceholder="Search an item"
                  checkboxClickHandler={selectItem}
                  headers={laborHeaders}
                  rows={labor}
                  errorMessage={
                    resourcesError && (
                      <p className="flex justify-center mt-5 items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {resourcesError?.message}
                      </p>
                    )
                  }
                />
              ) : (
                <div className="w-full h-full space-y-3 mt-4 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    src={resourcesIllustration}
                    alt="Material details illustration"
                  />
                  <p className="text-gray-700 text-2xl mt-5">No labor found</p>
                  <p className="text-dark-gray text-sm">
                    Labor will show up here once added
                  </p>
                </div>
              )}
            </div>
          </Tab>
        </Tabs>
        {!cardVisible && (
          <Card
            // cardRef={cardRef}
            className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
              selectedItem ? "translate-x-0 col-span-3" : "translate-x-full "
            }`}
          >
            {selectedItem ? (
              <Fragment>
                <h1 className="text-start w-full text-lg font-medium">
                  Item details
                </h1>
                <ul className="w-full text-xs space-y-3 p-2">
                  <li className="flex flex-col justify-between">
                    <h3 className="text-gray-700 text-base font-semibold">
                      {selectedItem.name || selectedItem.type}
                    </h3>
                  </li>
                  <hr />
                  {/* <li className="flex justify-between">
                    <span className="text-dark-gray">Item type</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.itemType}
                      </span>
                    </span>
                  </li> */}
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Unit price</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {(
                          selectedItem.unitPrice || selectedItem.payPerWorker
                        ).toLocaleString()}{" "}
                        ETB
                      </span>
                    </span>
                  </li>
                  <hr />
                  {/* <h4 className="text-gray-700 text-sm font-medium">
                    Already used
                  </h4>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">
                      {selectedItem.itemType === "Labor"
                        ? "Number of workers"
                        : "Quantity bought"}
                    </span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.quantity}
                      </span>
                      {selectedItem.unit}
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Total price spent</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.totalCost}
                      </span>
                      ETB
                    </span>
                  </li>
                  <h4 className="text-gray-700 text-sm font-medium">
                    Remaining
                  </h4>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Quantity remaining</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.remainingQuantity}
                      </span>
                      {selectedItem.unit}
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Total cost remaining</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.costRemaining}
                      </span>
                      ETB
                    </span>
                  </li> */}
                  <div className="flex flex-col space-y-2 ">
                    {selectedItem.projectId && (
                      <Button
                        className="shadow-sm hover:shadow-lg !bg-green-100/60 hover:!bg-green-200/80 !text-green-600 focus:!ring-0"
                        onClick={() => {
                          setActiveModal(activeTab);
                          setEditMode(true);
                        }}
                        disabled={selectedItem.projectId}
                      >
                        Edit{" "}
                        {activeTab === 0
                          ? "Material"
                          : activeTab === 1
                          ? "Equipment"
                          : "Labor"}
                      </Button>
                    )}
                    {/* <Button
                      onClick={showDeleteOneConfirmation}
                      className="shadow-sm hover:shadow-lg !bg-red-100/60 hover:!bg-red-200/80 !text-dark-red focus:!ring-0"
                    >
                      Delete{" "}
                      {activeTab === 0
                        ? "Material"
                        : activeTab === 1
                        ? "Equipment"
                        : "Labor"}
                    </Button> */}
                  </div>
                </ul>
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Card>
        )}
        {/* <Card
          cardRef={cardRef}
          className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
            cardVisible
              ? "translate-x-0 col-span-3"
              : "translate-x-full !bg-transparent"
          }`}
        >
          {cardVisible && (
            <Fragment>
              <h1 className="text-start w-full text-lg font-medium">
                Additional budget (ETB)
              </h1>
              <ul className="w-full text-xs space-y-3 p-2">
                <li className="flex flex-col justify-between">
                  <h3 className="text-gray-700 text-base font-semibold">
                    Items
                  </h3>
                </li>
                <hr />
                <li className="flex justify-between">
                  <span className="text-dark-gray">Material</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.materials - chart1.materials).toLocaleString()}{" "}
                      ETB
                    </span>
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray">Human labor</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.labors - chart1.labors).toLocaleString()} ETB
                    </span>
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray">resources</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.resourcess - chart1.resourcess).toLocaleString()}{" "}
                      ETB
                    </span>
                  </span>
                </li>
              </ul>
            </Fragment>
          )}
        </Card> */}
        {activeModal === 0 && (
          <NewMaterial
            refetch={getResources}
            setSuccess={setSuccess}
            setError={setError}
            isShown={activeModal === 0}
            hideModal={hideModal}
            selectedMaterial={selectedItem}
            editMode={editMode}
          />
        )}
        {activeModal === 1 && (
          <NewEquipment
            refetch={getResources}
            setSuccess={setSuccess}
            setError={setError}
            isShown={activeModal === 1}
            hideModal={hideModal}
            selectedEquipment={selectedItem}
            editMode={editMode}
          />
        )}
        {activeModal === 2 && (
          <NewLabor
            refetch={getResources}
            setSuccess={setSuccess}
            setError={setError}
            isShown={activeModal === 2}
            hideModal={hideModal}
            selectedLabor={selectedItem}
            editMode={editMode}
          />
        )}
        {/* {isConfirmDeleteOneShown && (
          <Confirm
            confirmButtonText="Delete"
            isLoading={isDeleting}
            confirmTitle="Delete"
            onClick={deleteOneHandler}
            onCancel={() => {
              setIsConfirmDeleteOneShown(false);
            }}
          >
            Are you sure you want to delete this{" "}
            {activeTab === 0
              ? "material"
              : activeTab === 1
              ? "equipment"
              : "labor"}
            ?
          </Confirm>
        )} */}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default ResourcesPage;
