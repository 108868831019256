import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Check2, PlusCircleFill, X } from "react-bootstrap-icons";
import Dropdown from "../../UI/Dropdown";
import { isObjectEmpty } from "../../../functions/validation";
import NewMaterial from "../NewMaterial";
import Button from "../../UI/Button";
import AddIcon from "@mui/icons-material/Add";
import { usePhase } from "../../../store/phase-context";

const Materials = (props) => {
  const {
    scrollToBottom,
    materials,
    setMaterials,
    refetch,
    allMaterials: allMaterialsProp,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
  } = props;
  const [editedItem, setEditedItem] = useState(null);
  const [newMaterialModalShown, setNewMaterialModalShown] = useState(false);
  const [allMaterials, setAllMaterials] = useState(allMaterialsProp);
  const { storePhaseData } = usePhase();

  const {
    value: material,
    isValid: materialIsValid,
    // isInvalid: materialIsInValid,
    inputChangeHandler: materialChangeHandler,
    inputBlurHandler: materialBlurHandler,
    reset: resetMaterial,
  } = useInput((value) => !isObjectEmpty(value));

  const {
    value: unitPrice,
    isValid: unitPriceIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: unitPriceChangeHandler,
    inputBlurHandler: unitPriceBlurHandler,
    reset: resetUnitPrice,
  } = useInput((value) => value !== "");

  const {
    value: quantity,
    isValid: quantityIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");
  const onMaterialChange = (material) => {
    unitPriceChangeHandler(material.unitPrice);
    materialChangeHandler(material);
  };
  const formIsValid = materials.length;
  useEffect(() => {
    setAllMaterials(allMaterialsProp);
  }, [allMaterialsProp]);
  console.log(materials);
  useEffect(() => {
    if (formIsValid) {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    } else {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            // return { ...s, isValid: false };
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    }
  }, [formIsValid]);

  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems([...deletedItems, materials[index]?.id]);
    }
    setMaterials(materials.filter((_, i) => i != index));
    scrollToBottom();
  };
  const hideModal = () => {
    setNewMaterialModalShown(false);
  };
  const addItem = () => {
    if (materialIsValid && quantityIsValid && unitPriceIsValid) {
      let found = false;
      const newMaterial = materials.map((mat) => {
        if (mat.material.id === material.id) {
          found = true;
          return {
            ...mat,
            unitPrice,
            quantity: parseInt(mat.quantity) + parseInt(quantity),
          };
        } else {
          return mat;
        }
      });
      setMaterials(newMaterial);
      if (!editMode) {
        storePhaseData({
          materials: newMaterial,
        });
      }
      if (!found) {
        const newMaterial = [
          ...materials,
          { material, quantity, unitPrice, unit: material.unit },
        ];
        setMaterials(newMaterial);
        if (!editMode) {
          storePhaseData({
            materials: newMaterial,
          });
        }
      }
      resetMaterial();
      resetUnitPrice();
      resetQuantity();
      scrollToBottom();
    }
  };
  const selectItem = (item, index) => {
    if (item) {
      setEditedItem({ item, index });
      materialChangeHandler(item?.material);
      unitPriceChangeHandler(item?.unitPrice);
      quantityChangeHandler(item?.quantity);
      scrollToBottom();
    }
  };
  const applyItem = () => {
    const { item, index } = editedItem;
    if (item && formIsValid) {
      const updatedMaterials = materials.map((item, i) => {
        if (index === i) {
          if (editMode) {
            return {
              ...item,
              material,
              unitPrice,
              quantity,
            };
          } else {
            return { material, unitPrice, quantity };
          }
        } else return item;
      });
      setMaterials(updatedMaterials);
      if (!editMode) {
        storePhaseData({
          materials: updatedMaterials,
        });
      }
      resetMaterial();
      resetUnitPrice();
      resetQuantity();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <div className="flex justify-between w-full">
        <h1 className="font-semibold">Materials</h1>
        <Button
          type="light"
          className="!px-4 !py-2 !text-gray-600 !bg-gray-100 hover:!bg-gray-200"
          onClick={() => {
            setNewMaterialModalShown(true);
          }}
        >
          <AddIcon />
          New Material
        </Button>
      </div>
      <Dropdown
        selectHandler={onMaterialChange}
        onBlur={materialBlurHandler}
        value={material.name}
        // error={materialIsInValid}
        label="Material name"
        helperText={<>Material is required</>}
        placeholder="Eg. Cement"
        items={allMaterials}
        propertyName="name"
        required={true}
        name="material"
      />
      <Input
        value={unitPrice}
        onChange={unitPriceChangeHandler}
        onBlur={unitPriceBlurHandler}
        // error={quantityIsInValid}
        helperText={<>Unit price is required</>}
        label="Unit price"
        placeholder="15"
        type="number"
      />
      <Input
        value={quantity}
        onChange={quantityChangeHandler}
        onBlur={quantityBlurHandler}
        suffix={material.unit}
        // error={quantityIsInValid}
        helperText={<>Quantity is required</>}
        label="Quantity"
        placeholder="15"
        type="number"
      />
      {editedItem !== null ? (
        <span className="flex justify-between">
          <button
            disabled={!(materialIsValid && quantityIsValid && unitPriceIsValid)}
            type="button"
            className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
            onClick={() => applyItem()}
          >
            <Check2 className="mr-1 text-[15px]" />
            Update item
          </button>
          <button
            disabled={!(materialIsValid && quantityIsValid && unitPriceIsValid)}
            type="button"
            className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
            onClick={() => {
              setEditedItem(null);
              resetMaterial();
              resetQuantity();
              resetUnitPrice();
            }}
          >
            <X className="mr-1 text-[15px]" />
            Cancel
          </button>
        </span>
      ) : (
        <button
          disabled={!(materialIsValid && quantityIsValid && unitPriceIsValid)}
          type="button"
          className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
          onClick={addItem}
        >
          <PlusCircleFill className="mr-1 text-[15px]" />
          Add item
        </button>
      )}
      <div>
        {materials.length !== 0 && (
          <table className="max-w-full overflow-auto horizontal-scrollbar block text-xs text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Material
                </th>
                <th scope="col" className="px-6 py-3">
                  Unit price
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {materials.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.material?.name}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {parseFloat(item.unitPrice).toLocaleString()}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {item.quantity} {item.unit || item.material.unit}
                  </td>
                  <td className="flex">
                    <IconButton
                      className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                      type="rounded"
                      onClick={() => selectItem(item, index)}
                    >
                      <EditIcon className="!w-4 !h-4" />
                    </IconButton>
                    <IconButton
                      className="group-hover:flex border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                      type="rounded"
                      onClick={() => removeItem(index)}
                    >
                      <DeleteIcon className="!w-4 !h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {newMaterialModalShown && (
          <NewMaterial
            refetch={refetch}
            setSuccess={() => {}}
            setError={() => {}}
            isShown={newMaterialModalShown}
            hideModal={hideModal}
            selectedMaterial={null}
            editMode={editMode}
          />
        )}
      </div>
    </div>
  );
};

export default Materials;
