import Button from "../UI/Button";
import Checkbox from "../UI/Checkbox";
import Card from "../UI/Card";
import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import Toast from "../UI/Toast";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../store/project-context";
import success from "../../assets/illustrations/success.svg";
import complete from "../../assets/illustrations/complete.svg";
import testComplete from "../../assets/illustrations/tests-complete.svg";
import { useAuthUser } from "react-auth-kit";
import { useNotification } from "../../store/notification-context";

const SET_CHECKED = gql`
  mutation MyMutation($_in: [uuid!]!) {
    update_projects_tests(
      where: { id: { _in: $_in } }
      _set: { isChecked: true }
    ) {
      affected_rows
    }
  }
`;

const SET_COMPLETED_AT = gql`
  mutation MyMutation($completed_at: timestamptz!, $id: uuid!) {
    update_projects_milestones_by_pk(
      pk_columns: { id: $id }
      _set: { completed_at: $completed_at }
    ) {
      id
    }
  }
`;

const Complete = (props) => {
  const [setChecked, { loading: isTestsUpdating, error: updateTestsError }] =
    useMutation(SET_CHECKED);
  const [setCompletedAt] = useMutation(SET_COMPLETED_AT);

  const { tests } = props;
  const navigate = useNavigate();
  const auth = useAuthUser();
  const user = auth();
  const [error, setError] = useState(null);
  const [checkedTests, setCheckedTests] = useState([]);
  const viewRef = useRef();
  const { currentProject } = useProject();
  const { sendNotification } = useNotification();

  useEffect(() => {
    if (tests.length)
      viewRef.current && viewRef.current.scrollIntoView({ behavior: "smooth" });
  }, [tests]);
  const changeTestIsChecked = (id, checked) => {
    if (checked) {
      setCheckedTests([...checkedTests, id]);
    } else {
      setCheckedTests(checkedTests.filter((el) => el !== id));
    }
  };

  const submitHandler = async () => {
    try {
      if (currentProject.id) {
        const response = await setChecked({
          variables: {
            _in: checkedTests,
          },
        });
        if (
          !isTestsUpdating &&
          response.data.update_projects_tests.affected_rows
        ) {
          if (tests.length === checkedTests.length) {
            setCompletedAt({
              variables: {
                completed_at: new Date(),
                id: props.selectedMilestone.id,
              },
            });
            sendNotification({
              tag: "MILESTONE",
              title: "Milestone completed",
              description: `${props.selectedMilestone.name} phase is complete`,
              user_ids: [user.id],
            });
          }
          navigate("/report", {
            state: {
              success: `${checkedTests.length} ${
                checkedTests.length > 1 ? "tests" : "test"
              } marked as completed`,
            },
          });
        }
      } else {
        if (!currentProject.id) {
          setError("Current project not found");
        }
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  return (
    <>
      {tests?.length ? (
        <div ref={viewRef} className="col-span-8">
          <div className="flex w-full">
            <div className="flex flex-col w-1/2">
              <Card className="mt-2 shadow-sm">
                <div className="flex flex-col items-center p-3">
                  <h1 className="text-primary font-medium text-xl mb-6">
                    All tasks in this phase are completed
                  </h1>
                  <img className="w-2/3" src={complete} alt="Complete" />
                </div>
              </Card>
              <Card className="mt-2 shadow-sm">
                <div className="">
                  <h3 className="font-medium">Quality Tests</h3>
                  <div className="space-y-2 mt-2">
                    {tests.map((item, index) => (
                      <Checkbox
                        id={index}
                        key={index}
                        onClick={(checked) =>
                          changeTestIsChecked(item.id, checked)
                        }
                        label={item.name}
                      />
                    ))}
                  </div>
                </div>
              </Card>
            </div>
            <Card className="flex items-center justify-center mt-2 ml-4 w-1/2 shadow-sm">
              <img className="w-3/4" src={success} alt="Success" />
            </Card>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => {
                navigate("/report");
              }}
              className="mr-3"
              type="light"
            >
              Cancel
            </Button>
            <Button
              isLoading={isTestsUpdating}
              onClick={submitHandler}
              type="submit"
              disabled={checkedTests.length === 0}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div className="col-span-8 flex flex-col items-center justify-center">
          <img className="w-1/2" src={testComplete} alt="Success" />
          <h1 className="text-center mt-8 font-medium text-3xl text-primary self-center justify-self-center">
            This phase is complete
          </h1>
        </div>
      )}
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
    </>
  );
};

export default Complete;
